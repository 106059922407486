<template>
<el-row>
    <el-col>
        <el-card>
            <div slot="header" class="clearfix">
                <h2 class="page-header">
                    Better deals
                    <el-button icon="el-icon-download" class="pull-right" size="small"
                               @click="exportCsv">
                        Export CSV
                    </el-button>
                    <el-button icon="el-icon-search" class="pull-right" size="small"
                               @click="filtering = !filtering">
                        Filter
                    </el-button>
                </h2>
                <transition name="el-zoom-in-top">
                    <div v-if="filtering">
                        <el-card>
                            <el-form :inline="true">
                                <el-form-item label="Date Range">
                                    <el-date-picker
                                        v-model="filters[0].value"
                                        type="daterange"
                                        range-separator="-"
                                        format="yyyy-MM-dd"
                                        value-format="timestamp"
                                        start-placeholder="Start date"
                                        end-placeholder="End date"
                                        clearable>
                                    </el-date-picker>
                                </el-form-item>
                            </el-form>
                        </el-card>
                    </div>
                </transition>
            </div>
            <data-tables-server :data="better" @query-change="loadData"
                                class="clickable-rows"
                                :table-props="{defaultSort: { prop: 'id', order: 'descending' }}"
                                :pagination-props="{background: true, pageSizes: [15, 30, 60,100]}"
                                :current-page="currentPage" :page-size="pageSize" :total="total"
            :filters="filters">
                <el-table-column prop="id" label="ID" width="60" sortable="custom" />
                <el-table-column prop="difference" label="Difference" sortable="custom" >
                    <template slot-scope="scope">
                        &pound;{{scope.row.difference}}
                    </template>
                </el-table-column>
                <el-table-column prop="product.name" label="Tariff">
                    <template slot-scope="scope">
                        <router-link :to="{name: 'SuppliersShow', params: {id: scope.row.product.supplier.id}, query: {name:scope.row.product.name }}">
                            {{scope.row.product.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="product.supplier.name" label="Supplier" sortable="custom">
                    <template slot-scope="scope">
                        <router-link :to="{name: 'SuppliersShow', params: {id: scope.row.product.supplier.id}}">
                       {{scope.row.product.supplier.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="recommended.name" label="Recommended tariff">
                    <template slot-scope="scope">
                        <router-link :to="{name: 'SuppliersShow', params: {id: scope.row.recommended.supplier.id}, query: {name:scope.row.recommended.name }}">
                            {{scope.row.recommended.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="recommended.supplier.name" label="Recommended supplier">
                    <template slot-scope="scope">
                        <router-link :to="{name: 'SuppliersShow', params: {id: scope.row.recommended.supplier.id}}">
                       {{scope.row.recommended.supplier.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Date"  sortable="custom" >
                    <template slot-scope="scope">
                        {{scope.row.created_at | dateFormat}}
                    </template>
                </el-table-column>
            </data-tables-server>
        </el-card>
    </el-col>
</el-row>
</template>

<script>
import paginationAndFiltering from "@/mixins/paginationAndFiltering";
import api from "@/lib/api";

const moment = require('moment');
const fileDownload = require('js-file-download');
export default {
name: "BetterDeals",
    mixins: [paginationAndFiltering],
    data() {
        return {
            better: [],
            filters: [
                {
                    value: this.$route.query.range || '',
                    prop: 'range'
                }],
            queryInfo: null,
        }
    },
    filters: {
        dateFormat: function (date) {
            return moment(date * 1000).format('YYYY-MM-DD HH:mm')
        }
    },
    methods: {
        loadData: function (queryInfo) {
            this.queryInfo = queryInfo;
            this.loadDataParent(queryInfo)
            let that = this;
            if(queryInfo.filters[0] && queryInfo.filters[0].value){
                that.query.from = queryInfo.filters[0].value[0] / 1000
                that.query.to = queryInfo.filters[0].value[1] / 1000
            }
    
            if(that.sort.order && that.sort.prop === 'product.supplier.name'){
                that.query.order = 'supplier';
            }
            
            that.$Progress.start()
            api.better(that.query).then(response => {
                that.better = response.data.data
                if (response.data.pagination) {
                    that.setPagination(response.data.pagination)
                }
                that.$Progress.finish()
            }).catch(() => {
                that.$Progress.fail()
            })
        },
        exportCsv: function (){
            api.betterExportCsv(this.query, fileDownload).then(response => {
                fileDownload(response.data, `better_deals_export-${moment().format("YYYY-MM-DD_HH.mm.ss")}.csv`)
            })
        }
    },
}
</script>

<style scoped>

</style>
