<template>
    <el-switch v-model="localValue" active-color="#a94442" inactive-color="#3c763d" @change="handleChange"/>
</template>

<script>
export default {
    name: "SwitchWrap",
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true
        },
        id: {
            type: [String, Number, Object]
        }
    },
    data() {
        return {
            localValue: this.value
        }
    },
    methods: {
        handleChange(val) {
            this.$emit('change', val, this.id)
            this.$emit('update:value', val)
        }
    },
    watch: {
        value(val) {
                this.localValue = val
        }
    }
}
</script>

<style scoped>

</style>
