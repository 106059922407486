<template>
    <div>
    <el-row v-if="payout">
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h4 class="float-left">Payout details</h4>
                    
                    <div class="float-right" v-if="payout.paid_at === 0">
                        <el-popconfirm @onConfirm="confirmPayment(payout.id)"
                                       confirmButtonText="Yes" cancelButtonText="No"
                                       title="Are you sure the payment has been sent?">
                            
                            <el-button type="warning" size="small" slot="reference">
                                Pay now
                            </el-button>
                        </el-popconfirm>
                    </div>
                </div>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-row :gutter="20">
                            <el-col :span="10" class="font-weight-bold text-right">User</el-col>
                            <el-col :span="14">
                                <router-link :to="{name: 'UsersShow', params: {id: payout.user.id }}"
                                             v-if="payout.user">
                                    {{ payout.user.title }}. {{ payout.user.first_name }} {{ payout.user.last_name }}
                                </router-link>
                            </el-col>
                        </el-row>
                        <el-divider class="m-2"/>
                        <el-row :gutter="20">
                            <el-col :span="10" class="font-weight-bold text-right">Amount</el-col>
                            <el-col :span="14">&pound;{{ payout.amount }}</el-col>
                        </el-row>
                        <el-divider class="m-2"/>
                        <el-row :gutter="20">
                            <el-col :span="10" class="font-weight-bold text-right">Requested date</el-col>
                            <el-col :span="14">
                                {{ payout.created_at | dateFormat }}
                            </el-col>
                        </el-row>
                        <el-divider class="m-2"/>
                        <el-row :gutter="20">
                            <el-col :span="10" class="font-weight-bold text-right">Payout date</el-col>
                            <el-col :span="14">
                                <span v-if="payout.paid_at !== 0">{{ payout.paid_at | dateFormat }}</span>
                                <div v-else>
                                <span class="text-warning ">
                                    Pending
                                </span>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </el-card>
            
        </el-col>
    </el-row>
        <br>
        <el-row v-if="payout">
            <el-col>
                <referrals :for-payout="payout.id"/>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import api from "@/lib/api";
import Referrals from "@/views/dashboard/Referrals";

const moment = require('moment');
export default {
    name: "PayoutsShow",
    components: {Referrals},
    data() {
        return {
            payout: null
        }
    },
    methods: {
        setPayout(payout) {
            this.payout = payout
        },
        confirmPayment: function (id) {
            let that = this;
            this.$Progress.start()
            api.payoutConfirm(id).then(() => {
                api.payout(this.payout.id).then(response => {
                    that.setPayout(response.data.data)
                    that.$Progress.finish()
                }).catch(() => {
                    that.$Progress.fail()
                })
            }).catch(() => {
                that.$Progress.fail()
            })
        }
    },
    filters: {
        dateFormat: function (date) {
            return moment(date * 1000).format('YYYY-MM-DD HH:mm')
        }
    },
    beforeRouteEnter: function (to, from, next) {
        api.payout(to.params.id).then(response => {
            next(vm => {
                vm.setPayout(response.data.data)
            })
        }).catch(() => {
            next(vm => {
                vm.$Progress.fail()
            })
        })
    }
}
</script>

<style scoped>

</style>
