<template>
    <el-header>
        <router-link :to="{name: 'DashboardHome'}" class="logo" :class="{'logo-collapsed' : isCollapse }">
            <!-- mini logo for sidebar mini 50x50 pixels -->
            <span class="logo-mini" v-if="isCollapse"><b>MSP</b></span>
            <!-- logo for regular state and mobile devices -->
            <span class="logo-lg" v-else>My<b>Switch</b>Pro</span>
        </router-link>
        <nav class="d-flex align-items-center">
            <el-link plain icon="el-icon-menu" @click="isCollapse = !isCollapse" class="text-white"></el-link>
            <el-dropdown class=" ml-auto" trigger="click">
                <div class="d-flex align-items-center">
                    <el-avatar shape="circle" icon="el-icon-user-solid" class="mr-4"/>
                    <span class="el-dropdown-link text-white">
                        {{ currentUser.email }} <i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item><el-button type="text" @click="passwordDialogVisible = true">Change password</el-button> </el-dropdown-item>
                    <el-dropdown-item v-if="!$store.getters['auth/isMfaEnabled']"><el-button type="text" @click="dialogVisible = true">Enable MFA</el-button> </el-dropdown-item>
                    <el-dropdown-item v-else><el-button type="text" @click="disableMfa">Disable MFA</el-button> </el-dropdown-item>
                    <el-dropdown-item ><el-button type="text" @click="signout">Sign Out</el-button></el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </nav>
        <setup-mfa-dialog :visible.sync="dialogVisible"/>
        <change-password-dialog :visible.sync="passwordDialogVisible" />
    </el-header>
</template>

<script>
import {mapGetters} from "vuex";
import api from "@/lib/api";
import SetupMfaDialog from "@/components/SetupMfaDialog";
import ChangePasswordDialog from "@/components/ChangePasswordDialog";

export default {
    name: "AppHeader",
    components: {ChangePasswordDialog, SetupMfaDialog},
    props: {
        isCollapse: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            dialogVisible: false,
            passwordDialogVisible: false,
           
        }
    },
    computed: {
        ...mapGetters('auth', [
            'currentUser'
        ]),
    },
    methods: {
        signout: function () {
            this.$store.commit('auth/clear');
            api.token = null
            this.$router.push('Login')
        },
        disableMfa: function (){
            let that = this;
            api.mfaDisable().then(() => {
                that.$store.commit('auth/mfaEnabled', false)
                that.$message({
                    message: 'MFA Disabled',
                    type: 'success'
                })
            })
        }
    },
    watch: {
        isCollapse(newValue) {
            this.$emit('update:isCollapse', newValue)
        }
    },
}
</script>

<style scoped lang="scss">

.logo {
    font-size: 20px;
    line-height: 60px;
    text-align: center;
    color: white;
    display: block;
    float: left;
    width: 180px;
    
    &.logo-collapsed {
        width: 50px;
    }
    
    .logo-lg {
    
    }
}

.el-header {
    background-color: #009846;
}


.el-link {
    color: white;
    
    display: block;
    font-size: 20px;
    line-height: 60px;
    float: left;
    padding: 0 10px;
}
::v-deep {

}
</style>
