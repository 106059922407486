<template>
    <el-row>
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h2 class="page-header">Switches
                        <el-button v-if="!forUser" icon="el-icon-search" class="pull-right" size="small"
                                   @click="filtering = !filtering">
                            Filter
                        </el-button>
                    </h2>
                    <transition name="el-zoom-in-top">
                        <div v-if="filtering">
                            <el-card>
                                <el-form :inline="true">
                                    <el-form-item label="Status">
                                        <el-select v-model="filters[0].value" placeholder="Status" clearable>
                                            <el-option label="Pending" value="pending"/>
                                            <el-option label="In Progress" value="initiated"/>
                                            <el-option label="Objected" value="objection"/>
                                            <el-option label="Scheduled" value="scheduled"/>
                                            <el-option label="Canceled" value="canceled"/>
                                            <el-option label="Completed" value="completed"/>
                                            <el-option label="Waiting" value="waiting"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="MPRN">
                                        <el-input v-model="filters[1].value" clearable/>
                                    </el-form-item>
                                    <el-form-item label="MPAN">
                                        <el-input v-model="filters[2].value" clearable/>
                                    </el-form-item>
                                    <el-form-item label="User">
                                        <el-select v-model="filters[3].value" clearable filterable remote
                                                   :remote-method="searchUsers"
                                                   :loading="loading" placeholder="type in to search">
                                            <el-option v-for="(user, key) in users" :key="key" :value="user.id"
                                                       :label="`${user.title} ${user.first_name} ${user.last_name} (${user.email})`"/>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-card>
                        </div>
                    </transition>
                </div>
                <data-tables-server :data="switches" @query-change="loadData"
                                    :pagination-props="{background: true, pageSizes: [15, 30, 60,100]}"
                                    :current-page="currentPage" :page-size="pageSize" :total="total" :filters="filters">
                    <el-table-column prop="id" label="ID" width="60"/>
                    <el-table-column prop="user" label="User" v-if="!forUser">
                        <template slot-scope="scope">
                            <router-link :to="{name: 'UsersShow', params: {id: scope.row.user.id }}"
                                         v-if="scope.row.user">
                                {{ scope.row.user.first_name }} {{ scope.row.user.last_name }}
                            </router-link>
                            <span v-else>--</span>
                        </template>
                    </el-table-column><el-table-column prop="user" label="Email" v-if="!forUser">
                        <template slot-scope="scope">
                            <router-link :to="{name: 'UsersShow', params: {id: scope.row.user.id }}"
                                         v-if="scope.row.user">
                                {{ scope.row.user.email }}
                            </router-link>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="property.address" label="Address"/>
                    <el-table-column prop="property.postcode" label="Postcode">
                        <template slot-scope="scope">
                            {{scope.row.property.postcode.toUpperCase() }}
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="property.address_post_town" label="Town"/>
                    <el-table-column prop="created_at" label="Started date">
                        <template slot-scope="scope">
                            {{scope.row.created_at | dateFormat}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="expected" label="Switch date">
                        <template slot-scope="scope">
                            <span v-if="!scope.row.expected_switch_date">--</span>
                            <span v-else>{{scope.row.expected_switch_date}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="Status" >
                        <template slot-scope="scope">
                            <span :class="statusColor(scope.row.status)">{{scope.row.status | humanStatus }}</span>
                            <br />
                            <span v-if="scope.row.status === 'initiated'">({{scope.row.created_at | elapsed }} days)</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column :prop="null" label="Actions">
                        <template slot-scope="scope">
                            <el-button v-for="(button, key) in actionButtons(scope.row)"
                                       @click="button.handler" :key="key" v-bind="button.props">
                                {{ button.name }}
                            </el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" class="text-muted">No results</div>
                </data-tables-server>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import api from "@/lib/api";
import paginationAndFiltering from "@/mixins/paginationAndFiltering";
import statusColoring from "@/mixins/statusColoring";

const moment = require('moment');

export default {
    name: "Switches",
    mixins: [paginationAndFiltering, statusColoring],
    props: {
        forUser: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            properties: [],
            switches: [],
            loading: false,
            users: [],
            filters: [
                {
                    value: this.$route.query.status || '',
                    prop: 'status'
                },
                {
                    value: this.$route.query.mprn || '',
                    prop: 'mprn'
                },
                {
                    value: this.$route.query.mpan || '',
                    prop: 'mpan'
                },
                {
                    value: this.$route.query.user || '',
                    prop: 'user'
                },
            ],
            actionButtons: function (row) {
                return [
                    {
                        props: {
                            type: 'primary',
                        },
                        handler: () => {
                            this.$router.push({name: 'SwitchesShow', params: {id: row.id}})
                        },
                        name: 'Show'
                    }
                ]
            },
        }
    },
    filters: {
        dateFormat: function (date) {
            return moment(date * 1000).format('YYYY-MM-DD')
        },
        elapsed: function (date){
            return moment().diff(moment(date * 1000),'days')
        }
    },
    methods: {
        loadData: function (queryInfo) {
            this.loadDataParent(queryInfo)
            let that = this;
            that.$Progress.start()
            if (this.forUser) {
                that.query.user = this.forUser
            }
            api.switches(that.query).then(response => {
                that.switches = response.data.data
                that.setPagination(response.data.pagination)
                that.$Progress.finish()
            }).catch(() => {
                that.$Progress.fail()
            })
        },
        searchUsers: function (term) {
            if (!term) {
                return;
            }
            
            let that = this;
            that.loading = true;
            api.users({user: term}).then(response => {
                that.users = response.data.data;
            }).catch(() => {
                that.users = [];
            }).finally(() => {
                that.loading = false;
            })
        },
    },
}
</script>

<style scoped>

</style>
