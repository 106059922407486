<template>
    <el-card shadow="hover" class="p-0 my-2" :class="wrapBgColor">
        <div class="box-icon" :class="bgColor">
            <i :class="bgIcon"/>
        </div>
        <div class="box-content">
            <p class="box-content-title">{{ text }}</p>
            <p class="box-content-number">{{ number }}</p>
            <div  v-if="isProgress">
                <el-progress :percentage="percentage" :color="bgColor" />
            </div>
            <p class="box-progress-description">
                {{ progressDescription }}
            </p>
        </div>
    </el-card>
</template>

<script>
export default {
    name: "InfoBox",
    props: {
        'wrapBgColor': {
            type: String
        },
        'bgColor': {
            type: String
        },
        'bgIcon': {
            type: String
        },
        'text': {
            type: String,
            required: true
        },
        'number': {
            type: [String, Number],
            default: 0
        },
        'numberType': {
            type: String
        },
        'percentage': {
            type: Number,
            default: 0
        },
        'progressDescription': {
            type: String,
            default: ''
        },
        'isProgress': {
            type: Boolean,
            default: false
        }
    },
    computed: {
        parseNumber() {
            let result = this.number
            // FIXME: using constant
            switch (this.numberType) {
                case 'percentage':
                    result += '%'
                    break
                case 'comma':
                    result = Number(result).toLocaleString('en')
                    break
                default:
                    break
            }
            return result
        }
    }
}
</script>

<style scoped lang="scss">
.box-icon {
    width: 90px;
    height: 90px;
    text-align: center;
    font-size: 45px;
    line-height: 90px;
    float: left;
    color: white;
}

.box-content {
    padding: 5px 10px;
    margin-left: 90px;
    
    p {
        margin: 0;
    }
    
    > p.box-content-title {
        text-transform: uppercase;
    }
    
    > p.box-content-number {
        font-weight: bold;
        font-size: 18px;
    }
}

::v-deep {
    .el-card__body {
        padding: 0;
    }
}
</style>
