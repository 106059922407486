import '@/sass/app.scss';

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import clickOutside from '@/directives/click-outside'

import VueProgressBar from 'vue-progressbar'
import ElementUI from 'element-ui'
// import DataTables and DataTablesServer together
import { DataTables } from 'vue-data-tables'
import { DataTablesServer } from 'vue-data-tables'

Vue.config.productionTip = false

// set language to EN
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'

locale.use(lang)


Vue.use(VueProgressBar, {
  color: '#ecf0f5',
  failedColor: '#ff0000',
  thickness: '4px'
});

Vue.use(ElementUI)
Vue.use(DataTables)
Vue.use(DataTablesServer)

Vue.directive('click_outside', clickOutside)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
