import Vue from 'vue'

const EventBus = new Vue()

export default EventBus

export const events = {
    auth: {
        signin: 'auth.signin',
        signup: 'auth.signup',
        signout: 'auth.signout',
        notVerified: 'auth.not_verified',
    },
};
