<template>
    <div>
        <h4>Users</h4>
        <el-row :gutter="20">
            <el-col :span="6">
                <info-box
                    bgColor="bg-cyan"
                    bgIcon="fa fa-user"
                    text="Registered"
                    :number="stats.total_users_registered"
                ></info-box>
            </el-col>
            <el-col :span="6">
                <info-box
                    bgColor="bg-cyan"
                    bgIcon="fa fa-user"
                    text="Verified"
                    :number="stats.total_users_verified"
                ></info-box>
            </el-col>
            <el-col :span="6">
                <info-box
                    bgColor="bg-cyan"
                    bgIcon="fa fa-user"
                    text="Customers"
                    :number="stats.total_users_customers"
                ></info-box>
            </el-col>
            <el-col :span="6">
                <info-box
                    bgColor="bg-cyan"
                    bgIcon="fa fa-user"
                    text="Switched"
                    :number="stats.total_users_switched"
                ></info-box>
            </el-col>
            <el-col :span="6">
                <info-box
                    bgColor="bg-cyan"
                    bgIcon="fa fa-user"
                    text="% verified"
                    :number="stats.percentage_verified"
                ></info-box>
            </el-col>
        </el-row>
        <h4>Switches</h4>
        <el-row :gutter="20">
            <el-col :span="12">
                <info-box
                    bgColor="bg-teal"
                    bgIcon="fa fa-home"
                    text="Completed"
                    :number="stats.total_switches_completed"
                ></info-box>
            </el-col>
            <el-col :span="12">
                <info-box
                    bgColor="bg-teal"
                    bgIcon="fa fa-home"
                    text="In progress"
                    :number="stats.total_switches_in_progress"
                ></info-box>
            </el-col>
        </el-row>
        <h4>Savings</h4>
        <el-row :gutter="20">
            <el-col :span="12">
                <info-box
                    bgColor="bg-green"
                    bgIcon="fa fa-dollar"
                    text="Savings"
                    :number="stats.total_savings"
                ></info-box>
            </el-col>

            <el-col :span="12">
                <info-box
                    bgColor="bg-green"
                    bgIcon="fa fa-dollar"
                    text="Average Saving"
                    :number="stats.average_savings"
                ></info-box>
            </el-col>
        </el-row>
        <h4>Referrals</h4>
        <el-row :gutter="20">
            <el-col :span="6">
                <info-box
                    bgColor="bg-orange"
                    bgIcon="fa fa-refresh"
                    text="Total referrals"
                    :number="stats.total_referrals"
                ></info-box>
            </el-col>
            <el-col :span="6">
                <info-box
                    bgColor="bg-orange"
                    bgIcon="fa fa-refresh"
                    text="Total referrals achieved"
                    :number="stats.total_referrals_achieved"
                ></info-box>
            </el-col>
            <el-col :span="6">
                <info-box
                    bgColor="bg-orange"
                    bgIcon="fa fa-refresh"
                    text="Average Referrals"
                    :number="stats.average_referrals"
                ></info-box>
            </el-col>
            <el-col :span="6">
                <info-box
                    bgColor="bg-orange"
                    bgIcon="fa fa-refresh"
                    text="Average Referrals achieved"
                    :number="stats.average_referrals_achieved"
                ></info-box>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import api from "@/lib/api";
import InfoBox from "@/components/InfoBox";
export default {
    name: "Home",
    components: {InfoBox},
    data() {
        return {
            stats: {}
        }
    },
    methods: {
        setStats(stats) {
            this.stats = stats
        }
    },
    mounted() {
        let that = this;
        api.stats().then(response => {
           that.setStats(response.data.data)
        })
    },
    beforeRouteEnter: (to, from, next) => {
        api.stats().then(response => {
            next(vm => {
                vm.setStats(response.data.data)
            })
        })
    }
}
</script>

<style scoped>

</style>
