import {each} from "lodash";

export default {
    namespaced: true,
    state: {
        accessToken: null,
        auth: null,
        email: 'admin@myswitchpro.com',
        createdAt: null,
        mfaEnabled: null,
    },
    getters: {
        accessToken: (state) => state.accessToken,

        isAuthenticated: (state) => state.auth,

        currentUser: (state) => {
            return {
                email: state.email,
                createdAt: state.createdAt
            }
        },
        isMfaEnabled: (state ) => state.mfaEnabled
    },
    mutations: {
        auth: (state, payload) => state.auth = payload,

        accessToken: (state, payload) => state.accessToken = payload,

        clear: (state) => {
            each(state, function (v, k) {
                state[k] = null;
            });
        },

        mfaEnabled: (state, payload) => state.mfaEnabled = payload
    },
}
