<template>
    <div id="app" :class="[appClass]">
        <vue-progress-bar/>
        <el-container v-if="isAuthenticated" direction="vertical">
            <app-header :is-collapse.sync="isCollapse"/>
            <el-container>
                <el-aside :width="asideWidth" class="shadow">
                    <app-menu :is-collapse="isCollapse"/>
                </el-aside>
                <el-main class="p-0">
                    <app-content/>
                </el-main>
            </el-container>
        </el-container>
        <router-view v-else/>
    </div>
</template>
<script type="text/javascript">

import {mapGetters} from "vuex";
import {snakeCase} from "lodash";
import AppHeader from "@/components/AppHeader";
import AppMenu from "@/components/AppMenu";
import AppContent from "@/components/AppContent";
import EventBus, {events} from "@/lib/eventBus";
import api from "@/lib/api";


export default {
    name: 'app',
    components: {AppContent, AppMenu, AppHeader},
    computed: {
        ...mapGetters('auth', [
            'isAuthenticated',
        ]),
        appClass: function () {
            return snakeCase("route_" + this.$route.name);
        },
        asideWidth: function (){
            if(this.isCollapse){
                return '64px'
            }
            
            return '200px'
        }
    },
    data() {
        return {
            isCollapse: false
        }
    },
    mounted() {
        this.$Progress.finish()
        let that = this;
    
        EventBus.$on(events.auth.signout, function () {
            if(that.$store.getters["auth/isAuthenticated"]) {
                api.token = null
                that.$store.commit('auth/clear');
                that.$router.push({name: 'Login'});
            }
        });
    },
    created() {
        this.$Progress.start()
        
        this.$router.beforeEach((to, from, next) => {
            this.$Progress.start()
            next()
        })
        
        this.$router.afterEach(() => {
            this.$Progress.finish()
        })
    }
    
}
</script>
<style scoped lang="scss">
::v-deep {
    .el-main {
        min-height: calc(100vh - 60px);
    
    }
}
</style>
