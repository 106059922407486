<template>
    <el-row>
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h4 class="d-flex align-items-center justify-content-between">
                        {{ supplier.name }}
                        <el-button  v-if="!editMode" @click="editMode = true" icon="el-icon-edit" size="small">Edit</el-button>
                    </h4>
                    
                </div>
                <el-row>
                    <el-col :span="12">
                        <el-row :gutter="20">
                            <el-col :span="6" class="font-weight-bold text-right">Name</el-col>
                            <el-col :span="12">{{ supplier.name }}</el-col>
                        </el-row>
                        <el-divider class="m-3"/>
                        <el-row :gutter="20" class="d-flex align-items-center">
                            <el-col :span="6" class="font-weight-bold text-right">Email</el-col>
                            <el-col :span="12">
                                <div v-if="!editMode">
                                    <span v-if="supplier.email">{{ supplier.email }}</span>
                                    <span v-else>-- no email ---</span>
                                </div>
                                <div v-else>
                                    <el-input type="email" v-model="supplier.email"></el-input>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider class="m-3"/>
                        <el-row :gutter="20">
                            <el-col :span="6" class="font-weight-bold text-right">Type</el-col>
                            <el-col :span="12">{{ supplier.type | supplierType }}</el-col>
                        </el-row>
                        <el-divider class="m-3"/>
                        <el-row :gutter="20">
                            <el-col :span="6" class="font-weight-bold text-right">Logo</el-col>
                            <el-col :span="12">
                                <el-upload
                                    ref="avatarUploader"
                                    class="avatar-uploader"
                                    :action="api.getSupplierAvatarUploadUrl(supplier.id)"
                                    :headers="{'Authorization': 'Bearer '+$store.getters['auth/accessToken']}"
                                    name="image"
                                    :show-file-list="true"
                                    :on-success="handleAvatarSuccess"
                                    :before-upload="beforeAvatarUpload"
                                    :file-list="fileList"
                                    :limit="1"
                                >
                                    <el-button slot="trigger" size="small" type="primary">select file</el-button>
                                    <img v-if="supplier.image" :src="supplier.image" class="avatar">
                                </el-upload>
                            </el-col>
                        </el-row>
                        <el-divider class="m-3"/>
                        <el-row :gutter="20">
                            <el-col :span="6" class="font-weight-bold text-right">Active</el-col>
                            <el-col :span="12">
                                <span class="text-success" v-if="supplier.active">Active</span>
                                <span class="text-danger" v-else>Inactive</span>
                            </el-col>
                        </el-row>
                        <el-divider class="m-3"/>
                        <el-row :gutter="20">
                            <el-col :span="6" class="font-weight-bold text-right">Partner</el-col>
                            <el-col :span="12">
                                <div v-if="!editMode">
                                    <span class="text-success" v-if="supplier.supported">Yes</span>
                                    <span class="text-danger" v-else>No</span>
                                </div>
                                <div v-else>
                                    <el-switch
                                        v-model="supplier.supported"
                                        active-color="#3c763d"
                                        inactive-color="#a94442">
                                    </el-switch>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider class="m-3"/>
                        <el-row :gutter="20">
                            <el-col :span="6" class="font-weight-bold text-right">Featured</el-col>
                            <el-col :span="12">
    
                                <div v-if="!editMode">
                                    <span class="text-success" v-if="supplier.featured">Yes</span>
                                    <span class="text-danger" v-else>No</span>
                                </div>
                                <div v-else>
                                    <el-switch
                                        v-model="supplier.featured"
                                        active-color="#3c763d"
                                        inactive-color="#a94442">
                                    </el-switch>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider class="m-3"/>
                        <el-row :gutter="20">
                            <el-col :span="6" class="font-weight-bold text-right">Warm Home supported</el-col>
                            <el-col :span="12">
                                <div v-if="!editMode">
                                    <span class="text-success" v-if="supplier.warm">Yes</span>
                                    <span class="text-danger" v-else>No</span>
                                </div>
    
                                <div v-else>
                                    <el-switch
                                        v-model="supplier.warm"
                                        active-color="#3c763d"
                                        inactive-color="#a94442">
                                    </el-switch>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider class="m-3"/>
                        <el-row :gutter="20">
                            <el-col :span="6" class="font-weight-bold text-right">Exclude new tariffs by default?</el-col>
                            <el-col :span="12">
                                <div v-if="!editMode">
                                    <span class="text-success" v-if="supplier.default_excluded">Yes</span>
                                    <span class="text-danger" v-else>No</span>
                                </div>
    
                                <div v-else>
                                    <el-switch
                                        v-model="supplier.default_excluded"
                                        active-color="#3c763d"
                                        inactive-color="#a94442">
                                    </el-switch>
                                </div>
                            </el-col>
                        </el-row>
                        <el-divider class="m-3"/>
                        <el-row :gutter="20">
                            <el-col :span="6" class="font-weight-bold text-right">Switch safe period</el-col>
                            <el-col :span="12">
                                <div v-if="!editMode">
                                    {{supplier.safe_period || 0 }} days
                                </div>
    
                                <div v-else>
                                    <el-input-number
                                        v-model="supplier.safe_period" controls-position="right" size="small" >
                                    </el-input-number>
                                </div>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row v-if="editMode">
                    <el-col class="mt-4">
                        <el-button   @click="editMode = false" :disabled="running">cancel</el-button>
                        <el-button type="primary" @click="save" :disabled="running">Save</el-button>
                    </el-col>
                </el-row>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import api from "@/lib/api";

export default {
    name: "SupplierDetails",
    props: {
        supplier: {
            type: Object,
            default: null
        },
    },
    data() {
        return {
            editMode: false,
            running: false,
            fileList: [],
            api: api
        }
    },
    methods: {
        save: function () {
            let that = this;
            that.running = true
            api.updateSupplier(this.supplier.id, this.supplier).then(response => {
                if (response.data.status) {
                    that.editMode = false
                    this.$message({
                        message: 'Edit was successful',
                        type: 'success'
                    });
                }
            }).finally(() => {
                that.running = false
            })
        },
        handleAvatarSuccess() {
            let that = this;
            api.supplier(this.$route.params.id).then(response => {
                that.$emit('update:supplier', response.data.data)
                this.$refs.avatarUploader.clearFiles()
            });
            
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;
            
            if (!isJPG && !isPNG) {
                this.$message.error('Avatar picture must be JPG or PNG format!');
            }
            
            if (!isLt2M) {
                this.$message.error('Avatar picture size can not exceed 2MB!');
            }
            return (isJPG || isPNG) && isLt2M;
        },
    },
    filters: {
        supplierType: function (type) {
            switch (type) {
                case 'D':
                    return 'Dual energy'
                case 'E':
                    return 'Electricity Only'
                case 'G':
                    return 'Gas Only'
            }
        }
    },
}
</script>


<style scoped lang="scss">
::v-deep .el-upload__input {
    display: none;
}

::v-deep .avatar-uploader .avatar {
    max-width: 200px;
    display: block;
    margin-top: 1rem;
}
</style>
