<template>
    <div v-if="supplier">
        <supplier-details :supplier.sync="supplier" />
        <br >
        <supplier-products :supplier="supplier" />
    </div>
</template>

<script>
import api from "@/lib/api";
import SupplierDetails from "@/components/dashboard/SupplierDetails";
import SupplierProducts from "@/components/dashboard/SupplierProducts";

export default {
    name: "SuppliersShow",
    components: {SupplierProducts, SupplierDetails},
    data() {
        return {
            supplier: null,
            
        }
    },
    methods: {
        setSupplier(supplier) {
            this.supplier = supplier
        },
        
    },
    mounted() {
        let that = this;
        
        if(this.supplier != null) return
        
        api.supplier(this.$route.params.id).then(response => {
           that.setSupplier(response.data.data)
        });
    },
    beforeRouteEnter: (to, from, next) => {
        api.supplier(to.params.id).then(response => {
            next(vm => {
                vm.setSupplier(response.data.data)
            })
        })
    }
}
</script>
