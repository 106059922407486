<template>
    <el-row>
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h2 class="page-header">
                        Suppliers
                        <el-button icon="el-icon-search" class="pull-right" size="small"
                                   @click="filtering = !filtering">
                            Filter
                        </el-button>
                    </h2>
                    <transition name="el-zoom-in-top">
                        <div v-if="filtering">
                            <el-card>
                                <el-form :inline="true">
                                    <el-form-item label="Name">
                                        <el-input v-model="filters[0].value" clearable/>
                                    </el-form-item>
                                    <el-form-item label="Type">
                                        <el-select v-model="filters[1].value" clearable>
                                            <el-option label="Dual" value="D"/>
                                            <el-option label="Electricity" value="E"/>
                                            <el-option label="Gas" value="G"/>
                                        </el-select>
                                    </el-form-item>
                                    
                                    <el-form-item label="Active">
                                        <el-select v-model="filters[2].value" clearable>
                                            <el-option label="Yes" value="1"/>
                                            <el-option label="No" value="0"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Partner">
                                        <el-select v-model="filters[3].value" clearable>
                                            <el-option label="Yes" value="1"/>
                                            <el-option label="No" value="0"/>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-card>
                        </div>
                    </transition>
                </div>
                
                <data-tables-server :data="suppliers" @query-change="loadData"
                                    :pagination-props="{background: true, pageSizes: [15, 30, 60,100]}"
                                    :current-page="currentPage" :page-size="pageSize" :total="total" :filters="filters">
                    <el-table-column v-for="title in titles" :prop="title.prop" :label="title.label" :key="title.prop"
                                     v-bind="title.props || null"/>
                    <el-table-column prop="type" label="Type">
                        <template slot-scope="scope">
                            <p v-if="scope.row.type === 'D'">Dual</p>
                            <p v-if="scope.row.type === 'E'">Electricity</p>
                            <p v-if="scope.row.type === 'G'">Gas</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="active" label="Active">
                        <template slot-scope="scope">
                            <p class="text-success" v-if="scope.row.active">Active</p>
                            <p class="text-danger" v-else>Inactive</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="partner" label="Partner">
                        <template slot-scope="scope">
                            <p class="text-success" v-if="scope.row.supported">Yes</p>
                            <p class="text-danger" v-else>No</p>
                        </template>
                    </el-table-column>
                    <el-table-column :prop="null" label="Actions">
                        <template slot-scope="scope">
                            <el-button v-for="(button, key) in actionButtons(scope.row)"
                                       @click="button.handler" :key="key" v-bind="button.props">
                                {{ button.name }}
                            </el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" class="text-muted">No results</div>
                </data-tables-server>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import api from "@/lib/api";
import paginationAndFiltering from "@/mixins/paginationAndFiltering";

export default {
    name: "Suppliers",
    components: {},
    mixins: [paginationAndFiltering],
    data() {
        return {
            suppliers: [],
            filters: [
                {
                    value: this.$route.query.name || '',
                    prop: 'name'
                },
                {
                    value: this.$route.query.type || '',
                    prop: 'type'
                },
                {
                    value: this.$route.query.active || '',
                    prop: 'active'
                },
                {
                    value: this.$route.query.supported || '',
                    prop: 'supported'
                },
            ],
            titles: [
                {
                    prop: "id",
                    label: "ID",
                    props: {
                        width: '60px'
                    }
                },
                {
                    prop: "name",
                    label: "Name"
                },
            ],
            actionButtons: function (row) {
                return [
                    {
                        props: {
                            type: 'primary',
                        },
                        handler: () => {
                            this.$router.push({name: 'SuppliersShow', params: {id: row.id}})
                        },
                        name: 'Show'
                    }
                ]
            },
            selected: [],
        }
    },
    methods: {
        setSuppliers: function (suppliers) {
            this.suppliers = suppliers
        },
        loadData: function (queryInfo) {
            this.loadDataParent(queryInfo)
            let that = this;
            that.$Progress.start()
            api.suppliers(that.query).then(response => {
                that.setSuppliers(response.data.data)
                that.setPagination(response.data.pagination)
                that.$Progress.finish()
            }).catch(() => {
                that.$Progress.fail()
            })
        },
    },
}
</script>

<style scoped>

</style>
