<template>
    <div>
        <el-row v-if="user">
            <el-col>
                <el-card>
                    <div slot="header" class="clearfix">
                        <h4>User details</h4>
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">Name</el-col>
                                <el-col :span="14">{{ user.title }}. {{ user.first_name }} {{ user.last_name }}</el-col>
                            </el-row>
                            <el-divider class="m-2"/>
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">Email</el-col>
                                <el-col :span="14">{{ user.email }}</el-col>
                            </el-row>
                            <el-divider class="m-2"/>
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">Phone</el-col>
                                <el-col :span="14">{{ user.phone }}</el-col>
                            </el-row>
                            <el-divider class="m-2"/>
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">Referral code</el-col>
                                <el-col :span="14">{{ user.code }}</el-col>
                            </el-row>
                            <el-divider class="m-2"/>
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">Balance</el-col>
                                <el-col :span="14">&pound;{{ user.balance }}</el-col>
                            </el-row>
                            <el-divider class="m-2"/>
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">Verified</el-col>
                                <el-col :span="14"><span class="text-success" v-if="user.verified">Yes</span>
                                    <span class="text-danger" v-else>No</span></el-col>
                            </el-row>
                            <el-divider class="m-2"/>
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">Customer</el-col>
                                <el-col :span="14">
                                    <span class="text-success" v-if="user.direct_debit">Yes</span>
                                    <span class="text-danger" v-else>No</span>
                                </el-col>
                            </el-row>
                            <el-divider class="m-2"/>
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">From Google</el-col>
                                <el-col :span="14">
                                    <span class="text-success" v-if="user.from_google">Yes</span>
                                    <span class="text-danger" v-else>No</span>
                                </el-col>
                            </el-row>
                            <el-divider class="m-2"/>
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">From Facebook</el-col>
                                <el-col :span="14">
                                    <span class="text-success" v-if="user.from_facebook">Yes</span>
                                    <span class="text-danger" v-else>No</span>
                                </el-col>
                            </el-row>
                            <el-divider class="m-2"/>
                            <el-row :gutter="20">
                                <el-col :span="10" class="font-weight-bold text-right">3rd Party Marketing Consent</el-col>
                                <el-col :span="14">
                                    <span class="text-success" v-if="user.marketing_consent">Yes</span>
                                    <span class="text-danger" v-else>No</span>
                                </el-col>
                            </el-row>
                        </el-col>
                        <el-col :span="12" v-if="user.contact_address">
                            <h5>Contact Address</h5>
                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <el-row :gutter="20" v-if="user.contact_address.sub_building">
                                        <el-col :span="10" class="font-weight-bold text-right">Sub building</el-col>
                                        <el-col :span="14">{{user.contact_address.sub_building}}</el-col>
                                    </el-row>
                                    <el-row :gutter="20" v-if="user.contact_address.building">
                                        <el-col :span="10" class="font-weight-bold text-right">Building</el-col>
                                        <el-col :span="14">{{user.contact_address.building}}</el-col>
                                    </el-row>
                                    <el-row :gutter="20" v-if="user.contact_address.street">
                                        <el-col :span="10" class="font-weight-bold text-right">Street</el-col>
                                        <el-col :span="14">{{user.contact_address.street}}</el-col>
                                    </el-row>
                                    <el-row :gutter="20" v-if="user.contact_address.postcode">
                                        <el-col :span="10" class="font-weight-bold text-right">Postcode</el-col>
                                        <el-col :span="14">{{user.contact_address.postcode}}</el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-card>
            
            </el-col>
        </el-row>
        <br>
        <el-row v-if="user">
            <el-col>
                <el-tabs v-model="activeTab">
                    <el-tab-pane label="Switches" name="switches">
                        <switches :for-user="user.id"/>
                    </el-tab-pane>
                    <el-tab-pane label="Referrals" name="referrals">
                        <referrals :for-user="user.id" />
                    </el-tab-pane>
                    <el-tab-pane label="Payouts" name="payouts">
                        <payouts :for-user="user.id" />
                    </el-tab-pane>
                </el-tabs>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import api from "@/lib/api";
import Switches from "@/views/dashboard/Switches";
import Referrals from "@/views/dashboard/Referrals";
import Payouts from "@/views/dashboard/Payouts";

export default {
    name: "UsersShow",
    components: {Payouts, Referrals, Switches},
    data() {
        return {
            user: null,
            editMode: false,
            running: false,
            activeTab: window.location.hash ? window.location.hash.replace("#tab_", '') :'switches'
        }
    },
    methods: {
        setUser(user) {
            this.user = user
        }
    },
    mounted() {
        let that = this;
        
        if (that.user != null) return
        
        api.user(this.$route.params.id).then(response => {
            this.setUser(response.data.data)
        })
    },
    beforeRouteEnter: (to, from, next) => {
        api.user(to.params.id).then(response => {
            next(vm => {
                vm.setUser(response.data.data)
            })
        }).catch(() => {
            next(vm => {
                vm.$Progress.fail()
            })
        })
    }
}
</script>

<style scoped>

</style>
