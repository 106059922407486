<template>
    <el-dialog title="Change password" width="40%" :visible.sync="visibleLocal" @close="handleDialogClosed">
        <el-form label-position="left" label-width="auto" :model="form" ref="form" :rules="rules"
                 @validate="handleValidate">
            <el-form-item label="Current Password" prop="oldPassword">
                <el-input v-model="form.oldPassword" show-password></el-input>
            </el-form-item>
            <el-form-item label="New Password" prop="password">
                <el-input v-model="form.password" show-password></el-input>
            </el-form-item>
            <el-form-item label="Confirm Password" prop="passwordConfirm">
                <el-input v-model="form.passwordConfirm" show-password></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visibleLocal = false">Cancel</el-button>
            
            <el-popconfirm @onConfirm="submit"
                           confirmButtonText="Yes" cancelButtonText="No"
                           title="This will log you out of your current session!">
                
                <el-button slot="reference" type="primary" :disabled="!enableButton">Confirm</el-button>
            </el-popconfirm>
        </span>
    </el-dialog>
</template>

<script>
import api from "@/lib/api";

export default {
    name: "ChangePasswordDialog",
    props: {
        visible: Boolean,
    },
    data() {
        var confirmPassword = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please input the password again'));
            } else if (value !== this.form.password) {
                callback(new Error('Two passwords don\'t match!'));
            } else {
                callback();
            }
        };
        return {
            visibleLocal: this.visible,
            isValid: false,
            form: {
                oldPassword: null,
                password: null,
                passwordConfirm: null,
            },
            valid: {
                oldPassword: false,
                password: false,
                passwordConfirm: false,
            },
            rules: {
                oldPassword: [
                    {required: true, message: 'Please enter your current password', trigger: 'change'}
                ],
                password: [
                    {required: true, message: 'Please enter your new password', trigger: 'change'},
                    {min: 16, message: 'Password should be at least 16 characters long', trigger: 'change'}
                ],
                passwordConfirm: [
                    {required: true, message: 'Please confirm your new password', trigger: 'change'},
                    {validator: confirmPassword, trigger: 'change'}
                ]
            }
        }
    },
    watch: {
        visibleLocal: function (newValue) {
            this.$emit('update:visible', newValue)
        },
        visible: function (newValue) {
            this.visibleLocal = newValue
        }
    },
    computed: {
        enableButton: function () {
            return this.valid.oldPassword && this.valid.password && this.valid.passwordConfirm;
        }
    },
    methods: {
        handleDialogClosed() {
            this.$refs.form.resetFields();
        },
        handleValidate(prop, valid) {
            this.valid[prop] = valid
        },
        submit() {
            let that = this;
            this.$refs.form.validate((valid) => {
                if (valid) {
                    api.changePassword(that.form.oldPassword, that.form.password).then(() => {
                        that.$message({
                            message: 'Password changed',
                            type: 'success'
                        })
                        this.$store.commit('auth/clear');
                        api.token = null
                        this.$router.push('Login')
                    }).catch((error) => {
                        if(error.response.status === 400){
                            if(error.response.data.error.code === 11000){
                                that.$message({
                                    message: 'Current password does not match!',
                                    type: 'error'
                                })
                            }
                        } else {
                            that.$message({
                                message: 'Problem with setting new password',
                                type: 'error'
                            })
                        }
                        
                    });
                } else {
                    return false;
                }
            });
        }
    },
}
</script>

<style scoped>

</style>
