<template>
    <el-row>
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h2 class="page-header">Users
                        <el-button icon="el-icon-search" class="pull-right" size="small"
                                   @click="filtering = !filtering">
                            Filter
                        </el-button>
                    </h2>
                    <transition name="el-zoom-in-top">
                        <div v-if="filtering">
                            <el-card>
                                <el-form :inline="true">
                                    
                                    <el-form-item label="First Name">
                                        <el-input v-model="filters[0].value" clearable/>
                                    </el-form-item>
                                    <el-form-item label="Last name">
                                        <el-input v-model="filters[1].value" clearable/>
                                    </el-form-item>
                                    <el-form-item label="Email">
                                        <el-input v-model="filters[2].value" clearable/>
                                    </el-form-item>
                                    <el-form-item label="Verified">
                                        <el-select v-model="filters[3].value" clearable>
                                            <el-option label="Yes" value="1"/>
                                            <el-option label="No" value="0"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Customer">
                                        <el-select v-model="filters[4].value" clearable>
                                            <el-option label="Yes" value="1"/>
                                            <el-option label="No" value="0"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Date Range">
                                        <el-date-picker
                                            v-model="filters[5].value"
                                            type="daterange"
                                            range-separator="-"
                                            format="yyyy-MM-dd"
                                            value-format="timestamp"
                                            start-placeholder="Start date"
                                            end-placeholder="End date"
                                            clearable>
                                        </el-date-picker>
                                    </el-form-item>
                                </el-form>
                            </el-card>
                        </div>
                    </transition>
                </div>
                <data-tables-server :data="users" @query-change="loadData"
                                    :pagination-props="{background: true, pageSizes: [15, 30, 60,100]}"
                                    :current-page="currentPage" :page-size="pageSize" :total="total" :filters="filters">
                    
                    <el-table-column prop="id" label="ID" width="60px"/>
                    <el-table-column prop="title" label="Title"  width="60px"/>
                    <el-table-column prop="first_name" label="First name"/>
                    <el-table-column prop="last_name" label="Last name"/>
                    <el-table-column prop="email" label="Email"/>
                    <el-table-column prop="verified" label="Verified">
                        <template slot-scope="scope">
                            <p class="text-success" v-if="scope.row.verified">Yes</p>
                            <p class="text-danger" v-else>No</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="direct_debit" label="Customer">
                        <template slot-scope="scope">
                            <p class="text-success" v-if="scope.row.direct_debit">Yes</p>
                            <p class="text-danger" v-else>No</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="Registered">
                        <template slot-scope="scope">
                            {{ scope.row.created_at | dateFormat }}
                        </template>
                    </el-table-column>
                    
                    <el-table-column :prop="null" label="Actions">
                        <template slot-scope="scope">
                            <el-button v-for="(button, key) in actionButtons(scope.row)"
                                       @click="button.handler" :key="key" v-bind="button.props">
                                {{ button.name }}
                            </el-button>
                        </template>
                    </el-table-column>
                    <div slot="empty" class="text-muted">No results</div>
                </data-tables-server>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import api from "@/lib/api";
import paginationAndFiltering from "@/mixins/paginationAndFiltering";

const moment = require('moment');

export default {
    name: "Users",
    mixins: [paginationAndFiltering],
    data() {
        return {
            users: [],
            filters: [
                {
                    value: this.$route.query.first_name || '',
                    prop: 'first_name'
                },
                {
                    value: this.$route.query.last_name || '',
                    prop: 'last_name'
                },
                {
                    value: this.$route.query.email || '',
                    prop: 'email'
                },
                {
                    value: this.$route.query.verified || '',
                    prop: 'verified'
                },
                {
                    value: this.$route.query.direct_debit || '',
                    prop: 'direct_debit'
                },
                {
                    value: this.$route.query.range || '',
                    prop: 'range'
                },
            ],
            actionButtons: function (row) {
                return [
                    {
                        props: {
                            type: 'primary',
                        },
                        handler: () => {
                            this.$router.push({name: 'UsersShow', params: {id: row.id}})
                        },
                        name: 'Show'
                    }
                ]
            },
        }
    },
    filters: {
        dateFormat: function (date) {
            return moment(date * 1000).format('YYYY-MM-DD HH:mm')
        }
    },
    methods: {
        setUsers: function (users) {
            this.users = users
        },
        
        loadData: function (queryInfo) {
            this.loadDataParent(queryInfo)
            let that = this;
    
            if(queryInfo.filters[5] && queryInfo.filters[5].value){
                that.query.from = queryInfo.filters[5].value[0] / 1000
                that.query.to = queryInfo.filters[5].value[1] / 1000
            }
    
            
            that.$Progress.start()
            api.users(that.query).then(response => {
                that.setUsers(response.data.data)
                that.setPagination(response.data.pagination)
                that.$Progress.finish()
            }).catch(() => {
                that.$Progress.fail()
            })
        },
    },
}
</script>
