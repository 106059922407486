<template>
    <div class="container-fluid" v-if="!isAuthenticated">
        <div class="row ">
            <div class="col-12 col-sm-8 col-lg-4 mx-auto">
                <el-card slot="header" class="card login-form">
                    <div class="text-center">
                        <img src="../../assets/logo_small.png" class="img-fluid" alt="logo"/>
                    </div>
                        <el-row v-if="!mfaRequired">
                            <el-col>
                                <label for="username">Username</label>
                                <el-input type="text"  id="username" v-model="$v.username.$model"
                                          placeholder="Enter username" />
                                <div class="text-danger" v-if="$v.username.$error && !$v.username.required">
                                    Please enter your username!
                                </div>
                                <div class="text-danger" v-for="(err, key) in errors.username" :key="key">{{ err }}</div>
                            </el-col>
                        </el-row>
                        <el-row v-if="!mfaRequired">
                            <el-col>
                                <label for="password">Password</label>
                                <el-input type="password" class="" id="password" v-model="$v.password.$model"
                                          placeholder="Enter Password" />
                                <div class="text-danger" v-if="$v.password.$error && !$v.password.required">
                                    Please enter your password!
                                </div>
                                <div class="text-danger" v-if="$v.password.$error && !$v.password.minLength">
                                    Password too short. Minimum length is 16 characters!
                                </div>
                                <div class="text-danger" v-for="(err, key) in errors.password" :key="key">{{ err }}</div>
                            </el-col>
                        </el-row>
                    <el-row v-if="mfaRequired">
                        <el-col>
                            <label for="code">Authentication code</label>
                            <el-input type="text" class="" id="code" v-model="code"
                                      placeholder="Enter 6 digit code" />
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-button class="mt-3 w-100" type="primary" @click.prevent="loginWithCode" v-if="mfaRequired">Submit</el-button>
                            <el-button class="mt-3 w-100" type="primary" @click.prevent="login" v-else>Login</el-button>
                            <div class="text-danger" v-for="(err, key) in errors.general" :key="key">{{ err }}</div>
                        </el-col>
                    </el-row>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
import {validationMixin} from "vuelidate";
import {required, minLength} from "vuelidate/lib/validators"
import api, {errorCodes} from "@/lib/api";
import {mapGetters} from "vuex";
export default {
    name: "Login",
    mixins: [validationMixin],
    data() {
        return {
            username: "",
            password: "",
            code: null,
            mfaRequired: false,
            errors: {
                username: [],
                password: [],
                general: [],
            }
        }
    },
    validations: {
        username: { required},
        password: { required, minLength: minLength(16) }
    },
    computed: {
        ...mapGetters('auth', [
            'isAuthenticated',
        ]),
    },
    methods: {
        login: function () {
            this.errors = Object.fromEntries(Object.entries(this.errors).map(v => [v[0], []]));
    
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
    
            let that = this;
            api.login(this.username, this.password).then(response => {
                api.token = response.data.data.access_token;
                that.$store.commit('auth/accessToken',response.data.data.access_token);
                that.$store.commit('auth/auth',true);
                that.$store.commit('auth/mfaEnabled', false)
                this.$router.push({name: 'DashboardHome'})
            }).catch(error => {
                let response = error.response;
                let status = response.status;
                let data = response.data;

                if (response && status === 401 && data.error && data.error.code === errorCodes.unauthorized) {
                    that.errors.general.push('Invalid login details!')
                }
                if (response && status === 400 && data.error && data.error.code === errorCodes.mfaNotValid) {
                    that.mfaRequired = true
                }
            })
        },
        loginWithCode: function (){
            let that = this;
            api.login(this.username, this.password, that.code).then(response => {
                api.token = response.data.data.access_token;
                that.$store.commit('auth/accessToken',response.data.data.access_token);
                that.$store.commit('auth/auth',true);
                that.$store.commit('auth/mfaEnabled', true)
                this.$router.push({name: 'DashboardHome'})
            }).catch(error => {
                let response = error.response;
                let status = response.status;
                let data = response.data;
        
                if (response && status === 401 && data.error && data.error.code === errorCodes.unauthorized) {
                    that.errors.general.push('Invalid login details!')
                }
            })
        }
    },
}
</script>

<style scoped lang="scss">
@import "../../sass/base";

.container-fluid {
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("../../assets/leadin.png");
    background-size: cover;
}

.login-form {
    position: relative;
    top: 30vh;
    width: 100%;
}
</style>
