<template>
    <el-row>
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h2 class="page-header">
                        Pre-registrations
                      <el-button icon="el-icon-download" class="pull-right" size="small"
                                 @click="exportAnalytics">
                        Export Analytics
                      </el-button>
                    </h2>
                </div>
                <data-tables-server :data="registrations" @query-change="loadData"
                                    class="clickable-rows"
                                    :pagination-props="{background: true, pageSizes: [15, 30, 60,100]}"
                                    :current-page="currentPage" :page-size="pageSize" :total="total">
                    <el-table-column prop="id" label="ID" width="60" />
                    <el-table-column prop="title" label="Title" />
                    <el-table-column prop="full_name" label="Full Name"></el-table-column>
                    <el-table-column prop="business_name" label="Business Name"></el-table-column>
                    <el-table-column prop="email" label="Contact Email"></el-table-column>
                    <el-table-column prop="number" label="Contact Number"></el-table-column>
                    <el-table-column prop="created_at" label="Registered At">
                        <template slot-scope="scope">
                            <span>{{scope.row.created_at | dateFormat}}</span>
                        </template>
                    </el-table-column>
                </data-tables-server>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import api from "@/lib/api";
import paginationAndFiltering from "@/mixins/paginationAndFiltering";

const moment = require('moment');
const fileDownload = require('js-file-download');

export default {
name: "Registrations",
    mixins: [paginationAndFiltering],

    data() {
        return {
            registrations: [],
            loading: false,
        }
    },
    filters: {
        dateFormat: function (date) {
            return moment(date * 1000).format('YYYY-MM-DD')
        }
    },
    methods: {
        loadData: function (queryInfo) {
            this.loadDataParent(queryInfo)
            let that = this;
            that.$Progress.start()

            api.registrations(that.query).then(response => {
                that.registrations = response.data.data
                if (response.data.pagination) {
                    that.setPagination(response.data.pagination)
                }
                that.$Progress.finish()
            }).catch(() => {
                that.$Progress.fail()
            })
        },
        exportAnalytics: function () {
          api.registrationsExportAnalytics().then(response => {
            fileDownload(response.data, `analytics-${moment().format("YYYY-MM-DD_HH.mm.ss")}.csv`)
          })
        }
    },
}
</script>

<style scoped>

</style>
