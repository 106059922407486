<template>
    <el-dialog title="Scan the QR Code" width="30%" :visible.sync="visibleLocal">
        <el-input class="w-100" placeholder="6 digit auth code" v-if="codeConfirmInputVisible" v-model="code"/>
        <div class="text-center" v-else>
            <el-row>
                <el-col>
                    <img class="img-fluid" :src="qrCodeUrl"/>
                </el-col>
            </el-row>
            <el-row :gutter="20" class="mt-4">
                <el-col><p>Download Google authenticator</p></el-col>
                <el-col :span="12">
                    <a href="https://apps.apple.com/app/google-authenticator/id388497605" target="_blank">
                        <img src="../assets/apple.png" class="img-fluid" style="height: 51px"/>
                    </a>
                </el-col>
                <el-col :span="12">
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
                       target="_blank">
                        <img src="../assets/google_play.png" class="img-fluid" style="height: 51px"/>
                    </a>
                </el-col>
            </el-row>
        
        </div>
        <div class="text-danger" v-if="limitExceeded">
            Limit for confirmations exceeded! Try again Later.
        </div>
        <span slot="footer" class="dialog-footer">
    <el-button @click="visibleLocal = false">Cancel</el-button>
    <el-button type="primary" @click="codeConfirmInputVisible = true" v-if="!codeConfirmInputVisible">Enter Confirmation Code</el-button>
    <el-button type="primary" @click="confirmMfa" v-else>Confirm</el-button>
  </span>
    </el-dialog>
</template>

<script>
import api from "@/lib/api";

export default {
    name: "SetupMfaDialog",
    props: {
        visible: Boolean
    },
    data() {
        return {
            visibleLocal: this.visible,
            qrCodeUrl: null,
            code: null,
            limitExceeded: false,
            codeConfirmInputVisible: false,
        }
    },
    watch: {
        visibleLocal: function (newValue) {
            this.$emit('update:visible', newValue)
        },
        visible: function (newValue) {
            this.visibleLocal = newValue
        }
    },
    methods: {
        confirmMfa: function () {
            let that = this;
            api.mfaApprove(this.code).then(() => {
                that.visibleLocal = false
                that.codeConfirmInputVisible = false
                that.$store.commit('auth/mfaEnabled', true)
                that.$message({
                    message: 'MFA Enabled',
                    type: 'success'
                })
            }).catch(() => {
                that.limitExceeded = true
            })
        },
    },
    mounted() {
        let that = this;
        api.mfaGenerate().then(response => {
            that.qrCodeUrl = response.data.data.url;
        })
    }
}
</script>

<style scoped>

</style>
