<template>
    <el-row>
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h4>Products
                        <el-button icon="el-icon-search" class="pull-right" size="small" @click="filtering = !filtering">
                            Filter
                        </el-button>
                    </h4>
                    <el-row>
                        <el-col>
                            <transition name="el-zoom-in-top">
                                <el-card v-if="filtering">
                                    <el-form :inline="true">
                                        <el-form-item label="Name">
                                            <el-input v-model="filters[0].value" clearable/>
                                        </el-form-item>
                                    </el-form>
                                </el-card>
                            </transition>
                        </el-col>
                    </el-row>
                </div>
                
                <data-tables-server :data="products" @query-change="loadData" @row-click="rowClicked" ref="tableData"
                                    class="clickable-rows"
                                    :pagination-props="{background: true, pageSizes: [15, 30, 60,100]}"
                                    :current-page="currentPage" :page-size="pageSize" :total="total" :filters="filters">
                    <el-table-column type="expand" >
                        <template slot-scope="scope">
                            <h4><b>{{ scope.row.name }}</b> tariffs</h4>
                            <data-tables :data="scope.row.tariffs"  :pagination-props="{pageSizes: [15, 30, 60,100]}" :page-size="15">
                                <el-table-column prop="id" label="ID" />
                                <el-table-column prop="service_type" label="Service" >
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.service_type === 'D'">Dual</span>
                                        <span v-if="scope.row.service_type === 'E'">Electricity</span>
                                        <span v-if="scope.row.service_type === 'G'">Gas</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="payment_method" label="Payment method" />
    
                                <el-table-column prop="e7" label="E7" >
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.e7">yes</span>
                                        <span v-else>no</span>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="live" label="Live" >
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.live">yes</span>
                                        <span v-else>no</span>
                                    </template>
                                </el-table-column>
                            </data-tables>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="60" />
                    <el-table-column prop="name" label="Product Name" />
                    <el-table-column prop="has_excluded_tariffs" label="Available" >
                        <template slot-scope="scope">
                            <switch-wrap :value.sync="scope.row.has_excluded_tariffs" :id="scope.row.id" @change="handleChange"/>
                        </template>
                    </el-table-column>
                </data-tables-server>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import paginationAndFiltering from "@/mixins/paginationAndFiltering";
import api from "@/lib/api";
import SwitchWrap from "@/components/support/SwitchWrap";

export default {
    name: "SupplierProducts",
    components: {SwitchWrap},
    props: {
        supplier: {
            type: Object,
            default: null,
            required: true
        },
    },
    mixins: [paginationAndFiltering],
    data() {
        return {
            products: [],
            filters: [
                {
                    value: this.$route.query.name || '',
                    prop: 'name'
                }
            ],
        }
    },
    methods: {
        rowClicked(data, row, event) {
            if(event.target.className.startsWith('el-switch')) return;
            
            this.$refs.tableData.$refs.elTable.toggleRowExpansion(data);
        },
        loadData: function (queryInfo) {
            this.loadDataParent(queryInfo)
            let that = this;
            that.$Progress.start()
            api.supplierProducts(this.supplier.id, this.query).then(response => {
                that.products = response.data.data
                that.cacheProducts = response.data.data;
                that.setPagination(response.data.pagination)
                that.$Progress.finish()
            }).catch(() => {
                that.$Progress.fail()
            })
        },
        handleChange(exclude, productId){
            api.updateSupplierProductDefaultExclude(exclude, productId).then(() => {
                this.$message({
                    message: exclude ? 'Product excluded!' : 'Product included!',
                    type: 'success'
                })
            }).catch(() =>{
                this.$message({
                    message: 'Problem updating product!',
                    type: 'error'
                })
            })
        }
    },
}
</script>

<style scoped lang="scss">
.clickable-rows::v-deep {
    tbody tr td {
        cursor: pointer;
    }
    
    .el-table__expanded-cell {
        cursor: default;
    }
}
</style>
