<template>
    <el-row>
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h2 class="page-header">Support Tickets
                        <el-button icon="el-icon-search" class="pull-right" size="small"
                                   @click="filtering = !filtering">
                            Filter
                        </el-button>
                    </h2>
                    <transition name="el-zoom-in-top">
                        <div v-if="filtering">
                            <el-card>
                                <el-form :inline="true">
                                    
                                    <el-form-item label="User">
                                        <el-select v-model="filters[0].value" clearable filterable remote
                                                   :remote-method="searchUsers"
                                                   :loading="loading" placeholder="type in to search">
                                            <el-option v-for="(user, key) in users" :key="key" :value="user.id"
                                                       :label="`${user.first_name} ${user.last_name} (${user.email})`"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Topic">
                                        <el-select v-model="filters[1].value" clearable>
                                            <el-option value="Website Technical Issues"
                                                       label="Website Technical Issues"/>
                                            <el-option value="My Account Query" label="My Account Query"/>
                                            <el-option value="Switching Query" label="Switching Query"/>
                                            <el-option value="Referral Query" label="Referral Query"/>
                                            <el-option value="Other" label="Other"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Status">
                                        <el-select v-model="filters[2].value" clearable>
                                            <el-option label="Open" value="0"/>
                                            <el-option label="Closed" value="1"/>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-card>
                        </div>
                    </transition>
                </div>
                <data-tables-server :data="tickets" @query-change="loadData" @row-click="rowClicked" ref="tableData"
                                    class="clickable-rows"
                                    :pagination-props="{background: true, pageSizes: [15, 30, 60,100]}"
                                    :current-page="currentPage" :page-size="pageSize" :total="total" :filters="filters">
    
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <el-row>
                                <el-col :span="18">
                                    <el-row :gutter="20">
                                        <el-col :span="4" class="font-weight-bold text-right">From</el-col>
                                        <el-col :span="20">
                                            <router-link :to="{name: 'UsersShow', params: {id: scope.row.user.id }}"
                                                         v-if="scope.row.user">
                                                {{ scope.row.user.first_name }} {{ scope.row.user.last_name }}
                                                ( {{ scope.row.user.email }} )
                                            </router-link>
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col :span="4" class="font-weight-bold text-right">Subject</el-col>
                                        <el-col :span="20">
                                            {{ scope.row.topic }}
                                        </el-col>
                                    </el-row>
                                    <el-row :gutter="20">
                                        <el-col :span="4" class="font-weight-bold text-right">Message</el-col>
                                        <el-col :span="20">
                                            {{ scope.row.message }}
                                        </el-col>
                                    </el-row>
                                </el-col>
                                <el-col :span="6">
                                    <el-button v-if="!scope.row.status" type="success" class="pull-right"
                                               @click="toggle(scope.row)" :disabled="running">Resolve
                                    </el-button>
                                    <el-button v-else type="warning" class="pull-right" @click="toggle(scope.row)"
                                               :disabled="running">Reopen
                                    </el-button>
                                </el-col>
                            </el-row>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="ID" width="60"/>
                    <el-table-column prop="user.email" label="User">
                        <template slot-scope="scope">
                            <router-link :to="{name: 'UsersShow', params: {id: scope.row.user.id }}"
                                         v-if="scope.row.user">
                                {{ scope.row.user.first_name }} {{ scope.row.user.last_name }} ( {{ scope.row.user.email }} )
                            </router-link>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    
                    <el-table-column prop="topic" label="Topic"/>
                    <el-table-column prop="status" label="Status">
                        <template slot-scope="scope">
                            <span class="text-success" v-if="scope.row.status">Closed</span>
                            <span class="text-warning" v-else>Open</span>
                        </template>
                    </el-table-column>
                </data-tables-server>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import paginationAndFiltering from "@/mixins/paginationAndFiltering";
import api from "@/lib/api";

export default {
    name: "Tickets",
    mixins: [paginationAndFiltering],
    data() {
        return {
            tickets: [],
            loading: false,
            users: [],
            filters: [
                {
                    value: this.$route.query.user || '',
                    prop: 'user'
                },
                {
                    value: this.$route.query.topic || '',
                    prop: 'topic'
                },
                {
                    value: this.$route.query.status || '',
                    prop: 'status'
                },
            ],
            running: false
        }
    },
    methods: {
        rowClicked(row) {
            this.$refs.tableData.$refs.elTable.toggleRowExpansion(row);
        },
        loadData: function (queryInfo) {
            this.loadDataParent(queryInfo)
            let that = this;
            that.$Progress.start()
            api.tickets(that.query).then(response => {
                that.tickets = response.data.data
                if (response.data.pagination) {
                    that.setPagination(response.data.pagination)
                }
                that.$Progress.finish()
            }).catch(() => {
                that.$Progress.fail()
            })
        },
        toggle: function (ticket) {
            let that = this;
            this.running = true
            that.$Progress.start()
            api.ticketsStatusUpdate(ticket.id).then(() => {
                api.tickets(that.query).then(response => {
                    that.tickets = response.data.data
                    if (response.data.pagination) {
                        that.setPagination(response.data.pagination)
                    }
                    that.$Progress.finish()
                }).catch(() => {
                    that.$Progress.fail()
                })
                
            }).catch(() => {
                // that.$Progress.fail()
            }).finally(() => {
                that.running = false
            });
        },
        searchUsers: function (term) {
            if (!term) {
                return;
            }
            
            let that = this;
            that.loading = true;
            api.users({user: term}).then(response => {
                that.users = response.data.data;
            }).catch(() => {
                that.users = [];
            }).finally(() => {
                that.loading = false;
            })
        },
    }
}
</script>

<style scoped lang="scss">
.clickable-rows::v-deep {
    tbody tr td {
        cursor: pointer;
    }
    
    .el-table__expanded-cell {
        cursor: default;
    }
}
</style>
