<template>
    <el-row>
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h2 class="page-header">
                        Payouts
                        <el-button v-if="!forUser" icon="el-icon-search" class="pull-right" size="small"
                                   @click="filtering = !filtering">
                            Filter
                        </el-button>
                    </h2>
                    <transition name="el-zoom-in-top">
                        <div v-if="filtering">
                            <el-card>
                                <el-form :inline="true">
                                    <el-form-item label="User">
                                        <el-select v-model="filters[0].value" clearable filterable remote
                                                   :remote-method="searchUsers"
                                                   :loading="loading" placeholder="type in to search">
                                            <el-option v-for="(user, key) in users" :key="key" :value="user.id"
                                                       :label="`${user.title} ${user.first_name} ${user.last_name} (${user.email})`"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Paid out">
                                        <el-select v-model="filters[1].value" placeholder="Paid out" clearable>
                                            <el-option label="Yes" :value="true"/>
                                            <el-option label="No" :value="false"/>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-card>
                        </div>
                    </transition>
                </div>
                <data-tables-server :data="payouts" @query-change="loadData"
                                    class="clickable-rows"
                                    :pagination-props="{background: true, pageSizes: [15, 30, 60,100]}"
                                    :current-page="currentPage" :page-size="pageSize" :total="total" :filters="filters">
                    <el-table-column prop="id" label="ID" width="60" />
    
                    <el-table-column prop="user" label="User" v-if="!forUser">
                        <template slot-scope="scope">
                            <router-link :to="{name: 'UsersShow', params: {id: scope.row.user.id }}"
                                         v-if="scope.row.user">
                                {{ scope.row.user.first_name }} {{ scope.row.user.last_name }}
                            </router-link>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="Amount">
                        <template slot-scope="scope">
                            <span>&pound;{{scope.row.amount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="achieved_at" label="Requested date">
                        <template slot-scope="scope">
                            <span>{{scope.row.created_at | dateFormat}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="paid_at" label="Paid out">
                        <template slot-scope="scope">
                            <span v-if="scope.row.paid_at !== 0">{{scope.row.paid_at | dateFormat}}</span>
                            <div v-else>
                                <span class="text-warning ">
                                    Pending
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :prop="null" label="Actions">
                        <template slot-scope="scope">
                                <el-button v-for="(button, key) in actionButtons(scope.row)"
                                           @click="button.handler" :key="key" v-bind="button.props">
                                    {{ button.name }}
                                </el-button>
                        </template>
                    </el-table-column>
                </data-tables-server>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import api from "@/lib/api";
import paginationAndFiltering from "@/mixins/paginationAndFiltering";
const moment = require('moment');
export default {
name: "Payouts",
    mixins: [paginationAndFiltering],
    props: {
        forUser: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            payouts: [],
            loading: false,
            users: [],
            filters: [
                {
                    value: this.$route.query.user || '',
                    prop: 'user'
                },
                {
                    value: this.$route.query.paid_out? (this.$route.query.paid_out === 'true' ? true : false) : '',
                    prop: 'paid_out'
                },
            ],
        }
    },
    filters: {
        dateFormat: function (date) {
            return moment(date * 1000).format('YYYY-MM-DD HH:mm')
        }
    },
    methods: {
        loadData: function (queryInfo) {
            this.loadDataParent(queryInfo)
            let that = this;
            that.$Progress.start()

            if(this.forUser){
                that.query['user'] = this.forUser
            }
    
            api.payouts(that.query).then(response => {
                that.payouts = response.data.data
                if (response.data.pagination) {
                    that.setPagination(response.data.pagination)
                }
                that.$Progress.finish()
            }).catch(() => {
                that.$Progress.fail()
            })
        },
        searchUsers: function (term) {
            if (!term) {
                return;
            }
        
            let that = this;
            that.loading = true;
            api.users({user: term}).then(response => {
                that.users = response.data.data;
            }).catch(() => {
                that.users = [];
            }).finally(() => {
                that.loading = false;
            })
        },
    
        actionButtons: function (row) {
            let actions = [
                {
                    props: {
                        type: 'primary',
                    },
                    handler: () => {
                        this.$router.push({name: 'PayoutsShow', params: {id: row.id}})
                    },
                    name: 'Show'
                }
            ];
            return actions
        },
        
    },
}
</script>

<style scoped>

</style>
