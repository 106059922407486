export default {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            //
            // !!!!! if your target element will be removed by "v-if"
            // el.contains() won't find it contained in el
            // and will fire the method in the directive
            ///
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    },
}
