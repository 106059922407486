<template>
    <el-row v-if="swap">
        <el-col>
            <el-card class="mb-4" v-if="swap.recommended_tariff && swap.status !== 'completed'">
                <div slot="header" class="clearfix d-flex flex-row justify-content-between align-items-center">
                    <h4>New tariff summary</h4>
                
                </div>
                <el-row :gutter="20">
                    <el-col :span="16">
                        <deal-tariff-summary :deal="swap"></deal-tariff-summary>
                    </el-col>
                </el-row>
                <el-divider/>
                <el-row :gutter="20">
                    <el-col :span="6" class="font-weight-bold text-right">
                        Switching status
                    </el-col>
                    <el-col :span="6">
                        <span :class="statusColor(swap.status)">{{ swap.status | humanStatus }}</span>
                    </el-col>
                    <el-col :span="12">
                        <div>
                            <el-popconfirm @onConfirm="updateStatus('schedule')"
                                           confirmButtonText="Yes" cancelButtonText="No"
                                           title="Are you sure you want to schedule the switch?"
                                           v-if="swap.status === 'initiated' || swap.status === 'objection'">
                                <el-button size="medium" slot="reference" class="mx-2" :disabled="running" type="warning">Schedule
                                </el-button>
                            </el-popconfirm>
                            <el-popconfirm @onConfirm="updateStatus('object')"
                                           confirmButtonText="Yes" cancelButtonText="No"
                                           title="Are you sure you want to object the switch?"
                                           v-if="swap.status === 'initiated' || swap.status === 'scheduled'">
                                <el-button size="medium" slot="reference" class="mx-2" :disabled="running" type="danger">Object
                                </el-button>
                            </el-popconfirm>
                            <el-popconfirm @onConfirm="updateStatus('complete')"
                                           confirmButtonText="Yes" cancelButtonText="No"
                                           title="Are you sure you want to complete the switch?"
                                           v-if="swap.status === 'scheduled'">
                                <el-button size="medium" slot="reference" class="mx-2" :disabled="running" type="success">Complete
                                </el-button>
                            </el-popconfirm>
                            <el-popconfirm @onConfirm="updateStatus('cancel')"
                                           confirmButtonText="Yes" cancelButtonText="No"
                                           title="Are you sure you want to cancel the switch?"
                                           v-if="swap.status === 'objection'">
                                <el-button size="medium" slot="reference" class="mx-2" :disabled="running" type="danger">Cancel
                                </el-button>
                            </el-popconfirm>
                            <el-popconfirm @onConfirm="updateStatus('continue')"
                                           confirmButtonText="Yes" cancelButtonText="No"
                                           title="Are you sure you want to continue the switch?"
                                           v-if="swap.status === 'objection'">
                                <el-button size="medium" slot="reference" class="mx-2" :disabled="running" type="primary">Continue
                                </el-button>
                            </el-popconfirm>
                            <el-popconfirm @onConfirm="updateStatus('continue')"
                                           confirmButtonText="Yes" cancelButtonText="No"
                                           title="Are you sure you want to resume progress the switch?"
                                           v-if="swap.status === 'waiting'">
                                <el-button size="medium" slot="reference" class="mx-2" :disabled="running" type="primary">Resume progress
                                </el-button>
                            </el-popconfirm>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="mt-3">
                    <el-col :span="6" class="font-weight-bold text-right">
                        Proposed switching date
                    </el-col>
                    <el-col :span="6">
                        <span v-if="!editDate">
                        {{ swap.expected_switch_date || '---' }}
                        </span>
                        <div v-else>
                            <el-date-picker
                                v-model="switchDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="Pick a day">
                            </el-date-picker>
                        </div>
                    </el-col>
                    
                    <el-col :span="12">
                        <el-button size="medium" @click="editDate = true" v-if="!editDate" class="mx-2">Edit date</el-button>
                        <el-button size="medium" @click="editDate = false" v-if="editDate" class="mx-2">Cancel</el-button>
                        <el-button size="medium" @click="updateDate()" v-if="editDate" class="mx-2" type="primary">Update
                        </el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="mt-3" v-if="hasNotice('mprn')">
                    <el-col :span="6" class="font-weight-bold text-right">
                        Update MPRN number
                    </el-col>
                    <el-col :span="6">
                        <span v-if="!editMprn">
                            <span v-if="property.mprn">{{ property.mprn }}</span>
                            <span v-else>---</span>
                        </span>
                        <div v-else>
                            <el-input
                                v-model="mprn"
                                type="string"
                                placeholder="Enter MPRN">
                            </el-input>
                        </div>
                    </el-col>
                    
                    <el-col :span="12">
                        <el-button size="medium" @click="editMprn = true" v-if="!editMprn" class="mx-2">Edit MPRN</el-button>
                        <el-button size="medium" @click="editMprn = false" v-if="editMprn" class="mx-2">Cancel</el-button>
                        <el-button size="medium" @click="updateMprn()" v-if="editMprn" class="mx-2" type="primary">Update
                        </el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="20" class="mt-3" v-if="swap.status === 'initiated'">
                    <el-col :span="6" class="font-weight-bold text-right">
                        Resend report to supplier
                    </el-col>
                    <el-col :span="9" class="font-weight-bold text-left"  >
                        <span  v-if="swap.report_sent_at" class="text-success">Report sent</span>
                        <el-popconfirm @onConfirm="resendReport()"
                                       confirmButtonText="Yes" cancelButtonText="No"
                                       title="Are you sure you want to resend report to supplier?"
                                       
                                      >
                            <el-button class="mx-2" type="primary" size="medium" :disabled="running" slot="reference" v-if="!swap.report_sent_at">
                                Send
                            </el-button>
    
                            <el-button class="mx-2" type="secondary" size="medium" :disabled="running" slot="reference" v-else>
                                Send again
                            </el-button>
                        </el-popconfirm>
                       
                        
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="mb-4" v-if="!swap.recommended_tariff">
                <div slot="header" class="clearfix d-flex flex-row justify-content-between align-items-center">
                    <h4>New tariff summary</h4>
                </div>
                
                <p class="text-center text-muted">No better deal given</p>
            </el-card>
            <el-card>
                <div slot="header" class="clearfix">
                    <h4>Switch Details</h4>
                </div>
                <el-row :gutter="20">
                    <el-col :span="swap.status !== 'completed' ? 8: 12">
                        <h3 class="mb-4">Property Details</h3>
                        <el-divider></el-divider>
                        <el-row :gutter="20">
                            <el-col :span="8" class="font-weight-bold text-right">User</el-col>
                            <el-col :span="16">
                                <router-link v-if="user" :to="{name: 'UsersShow', params: {id: user.id}}">
                                    {{ user.first_name }} {{ user.last_name }}
                                </router-link>
                                <span v-else>
                                    ---
                                </span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8" class="font-weight-bold text-right">Email</el-col>
                            <el-col :span="16">
                                <router-link v-if="user" :to="{name: 'UsersShow', params: {id: user.id}}">
                                    {{ user.email }}
                                </router-link>
                                <span v-else>
                                    ---
                                </span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8" class="font-weight-bold text-right">Phone</el-col>
                            <el-col :span="16">
                                <router-link v-if="user" :to="{name: 'UsersShow', params: {id: user.id}}">
                                    {{ user.phone }}
                                </router-link>
                                <span v-else>
                                    ---
                                </span>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8" class="font-weight-bold text-right">Address</el-col>
                            <el-col :span="16">{{ property.address }}</el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8" class="font-weight-bold text-right">Town</el-col>
                            <el-col :span="16">{{ property.address_post_town }}</el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8" class="font-weight-bold text-right">Postal Code</el-col>
                            <el-col :span="16">{{ property.postcode.toUpperCase() }}</el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8" class="font-weight-bold text-right">Status</el-col>
                            <el-col :span="16"><span :class="statusColor(swap.status)">{{
                                    swap.status | humanStatus
                                }}</span></el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8" class="font-weight-bold text-right">MPAN</el-col>
                            <el-col :span="16">{{ property.mpan }}</el-col>
                        </el-row>
                        <el-row :gutter="20">
                            <el-col :span="8" class="font-weight-bold text-right">MPRN</el-col>
                            <el-col :span="16">{{ property.mprn }}
                            <span v-if="hasNotice('mprn')" class="text-danger">MPRN MISSING</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="swap.status !== 'completed' ? 8: 12" class="text-left" v-if="surveyData.currentEnergyBySameSupplier">
                        <h3 v-if="swap.status !== 'completed'">Current supply </h3>
                        <h3 v-else>Old Supply</h3>
                        <el-divider></el-divider>
                        <h5 class="mt-2 font-weight-bold">Type of energy usage</h5>
                        <p class="m-0 text-silver">
                            {{ swap.current_supply.energy_source | titleize(surveyData.currentEnergySource) }}
                        </p>
                        <div v-if="energySourceIs('D')">
                            <h5 class="mt-2 font-weight-bold">Is using the same supplier for Gas and Electricity?</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.energy_by_same_supplier | titleize(surveyData.currentEnergyBySameSupplier) }}
                            </p>
                        </div>
                        <div v-if="energySourceIs('D') && swap.current_supply.energy_by_same_supplier === '1'">
                            <h5 class="mt-2 font-weight-bold">Current energy supplier</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_product.supplier.name }}
                            </p>
                        </div>
                        <div v-if="energySourceIs('D') && swap.current_supply.energy_by_same_supplier === '1'">
                            <h5 class="mt-2 font-weight-bold">Current energy tariff</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_product.name }}
                            </p>
                        </div>
                        
                        <div v-if="energySourceIs('D') && swap.current_supply.energy_by_same_supplier === '1'">
                            <h5 class="mt-2 font-weight-bold">Energy payment method</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_payment_scheme | titleize(surveyData.currentPaymentSchemes) }}
                                <br/>
                                {{ swap.current_supply.electricity_payment_scheme | titleize(surveyData.currentPaymentSchemes, true) }}
                            </p>
                        </div>
                        <div v-if="energySourceIs(['D', 'E'])">
                            <h5 class="mt-2 font-weight-bold">Using Economy 7 meter</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.e7_state | titleize(surveyData.currentEconomy7State) }}
                            </p>
                        </div>
                        
                        <div
                            v-if="energySourceIs('E') || (energySourceIs('D') && swap.current_supply.energy_by_same_supplier === '0')">
                            <h5 class="mt-2 font-weight-bold">Current Electricity supplier</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_product.supplier.name }}
                            </p>
                        </div>
                        <div
                            v-if="energySourceIs('E') || (energySourceIs('D') && swap.current_supply.energy_by_same_supplier === '0')">
                            <h5 class="mt-2 font-weight-bold">Current Electricity tariff</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_product.name }}
                            </p>
                        </div>
                        
                        <div
                            v-if="energySourceIs('E') || (energySourceIs('D') && swap.current_supply.energy_by_same_supplier === '0')">
                            <h5 class="mt-2 font-weight-bold">Electricity payment method</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_payment_scheme | titleize(surveyData.currentPaymentSchemes) }}
                                <br/>
                                {{ swap.current_supply.electricity_payment_scheme | titleize(surveyData.currentPaymentSchemes, true) }}
                            </p>
                        </div>
                        <div
                            v-if="energySourceIs('G') || (energySourceIs('D') && swap.current_supply.energy_by_same_supplier === '0')">
                            <h5 class="mt-2 font-weight-bold">Current Gas supplier</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.gas_product.supplier.name }}
                            </p>
                        </div>
                        <div
                            v-if="energySourceIs('G') || (energySourceIs('D') && swap.current_supply.energy_by_same_supplier === '0')">
                            <h5 class="mt-2 font-weight-bold">Current Gas tariff</h5>
                            <p class="m-0 text-silver">
    
                                {{ swap.current_supply.gas_product.name }}
                            </p>
                        </div>
                        
                        <div
                            v-if="energySourceIs('G') || (energySourceIs('D') && swap.current_supply.energy_by_same_supplier === '0')">
                            <h5 class="mt-2 font-weight-bold">Gas payment method</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.gas_payment_scheme | titleize(surveyData.currentPaymentSchemes) }}
                                <br/>
                                {{ swap.current_supply.gas_payment_scheme | titleize(surveyData.currentPaymentSchemes, true) }}
                            </p>
                        </div>
                        <div v-if="energySourceIs(['D', 'E','G'])">
                            <h5 class="mt-2 font-weight-bold">Use of "Warm Home Discount"</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.warm_home_state | titleize(surveyData.currentWarmHomeState) }}
                            </p>
                        </div>
                    </el-col>
                    
                    <el-col :span="swap.status !== 'completed' ? 8: 12" class="text-left">
                        <h3>Energy usage </h3>
                        <el-divider></el-divider>
    
                        <div v-if="energySourceIs(['D', 'E'])">
                            <h5 class="mt-2 font-weight-bold">Know how much Electricity you use</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_usage_unit | titleize(surveyData.usageUnits) }}
                            </p>
                        </div>
                        <div v-if="swap.current_supply.electricity_usage_unit === 'gbp'">
                            <h5 class="mt-2 font-weight-bold">Paying</h5>
                            <p class="m-0 text-silver">
                                &pound; {{ swap.current_supply.electricity_usage_standard_amount }} /
                                {{ swap.current_supply.electricity_usage_period | titleize(surveyData.usagePeriods) }}
                            </p>
                        </div>
                        <div
                            v-if="swap.current_supply.electricity_usage_unit === 'kwh' && swap.current_supply.e7_state === '0'">
                            <h5 class="mt-2 font-weight-bold">Usage</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_usage_standard_amount }} kWh /
                                {{ swap.current_supply.electricity_usage_period | titleize(surveyData.usagePeriods) }}
                            </p>
                        </div>
                        <div
                            v-if="swap.current_supply.electricity_usage_unit === 'kwh' && swap.current_supply.e7_state === '1'">
                            <h5 class="mt-2 font-weight-bold">Daytime usage</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_usage_daily_amount }} kWh; /
                                {{ swap.current_supply.electricity_usage_period | titleize(surveyData.usagePeriods) }}
                            </p>
                            <h5 class="mt-2 font-weight-bold">Nighttime usage</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.electricity_usage_nightly_amount }} kWh; /
                                {{ swap.current_supply.electricity_usage_period | titleize(surveyData.usagePeriods) }}
                            </p>
                        </div>
    
                        <div v-if="energySourceIs(['D','G'])">
                            <h5 class="mt-2 font-weight-bold">Know how much Gas you use</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.gas_usage_unit | titleize(surveyData.usageUnits) }}</p>
                        </div>
    
                        <div v-if="swap.current_supply.gas_usage_unit === 'gbp'">
                            <h5 class="mt-2 font-weight-bold">Paying</h5>
                            <p class="m-0 text-silver">
                                &pound; {{ swap.current_supply.gas_usage_amount }} /
                                {{ swap.current_supply.gas_usage_period | titleize(surveyData.usagePeriods) }}
                            </p>
                        </div>
                        <div v-if="swap.current_supply.gas_usage_unit === 'kwh'">
                            <h5 class="mt-2 font-weight-bold">Usage</h5>
                            <p class="m-0 text-silver">
                                {{ swap.current_supply.gas_usage_amount }} kWh /
                                {{ swap.current_supply.gas_usage_period | titleize(surveyData.usagePeriods) }}
                            </p>
                        </div>
                        <div v-if="energySourceIs(['D','E'])">
                            <h5 class="mt-2 font-weight-bold">Electricity ONLY from Renewable Sources?</h5>
                            <p class="m-0 text-silver">
                                {{
                                    swap.current_supply.prefer_renewable_sources | titleize(surveyData.preferRenewableSources)
                                }}
                            </p>
                        </div>
                    </el-col>
                    <el-col v-if="swap.status === 'completed'" :span="swap.status !== 'completed' ? 8: 12" class="text-left">
                        <h3>New supply</h3>
                        <el-divider></el-divider>
                        <deal-tariff-summary :deal="swap"></deal-tariff-summary>
                    </el-col>
                </el-row>
            </el-card>
        
        </el-col>
    </el-row>
</template>

<script>
import api from "@/lib/api";
import statusColoring from "@/mixins/statusColoring";
import DealTariffSummary from "@/components/DealTariffSummary";

export default {
    name: "SwitchesShow",
    components: {DealTariffSummary},
    mixins: [statusColoring],
    data() {
        return {
            swap: null,
            editDate: false,
            editMprn: false,
            switchDate: null,
            mprn: null,
            running: false,
            surveyData: null,
        }
    },
    
    methods: {
        hasNotice: function (noticeProperty){
            return this.swap.notice && this.swap.notice.indexOf(noticeProperty) >= 0;
        },
        setSwitch(swap) {
            this.swap = swap
            this.switchDate = swap.expected_switch_date
        },
        setSurveyData(data) {
            this.surveyData = data;
        },
        energySourceIs: function (sourceType) {
            if (!Array.isArray(sourceType)) {
                sourceType = [sourceType]
            }
            return sourceType.indexOf(this.swap.current_supply.energy_source) >= 0
        },
        updateStatus(action) {
            let that = this;
            that.running = true
            api.updateSwitch(this.swap.id, action).then(response => {
                if (response.data.status) {
                    api.switchDetails(this.$route.params.id).then(response => {
                        that.setSwitch(response.data.data)
                    })
                    that.$message({
                        message: 'Switch Status updated!',
                        type: "success"
                    })
                } else {
                    that.$message({
                        message: 'Invalid status attempted!',
                        type: "error"
                    })
                }
            }).catch(() => {
                that.$message({
                    message: 'Problem with updating status!',
                    type: "error"
                })
            }).finally(() => {
                that.running = false
            })
        },
        updateDate() {
            let that = this;
            that.running = true;
            
            api.updateSwitchDate(this.swap.id, this.switchDate).then(response => {
                if (response.data.status) {
                    api.switchDetails(this.$route.params.id).then(response => {
                        that.setSwitch(response.data.data)
                        that.running = false
                        that.editDate = false
                    })
                }
            })
        },
        updateMprn() {
            let that = this;
            that.running = true;
            
            api.updateMprn(this.swap.id, this.mprn).then(response => {
                if (response.data.status) {
                    api.switchDetails(this.$route.params.id).then(response => {
                        that.setSwitch(response.data.data)
                        that.running = false
                        that.editMprn = false
                    })
                }
            })
        },
        resendReport: function (){
            let that = this;
            that.running = true;
    
            api.resendReport(this.swap.id).then(() => {
               
                api.switchDetails(this.$route.params.id).then(response => {
                    that.$message({
                        message: 'Report sent',
                        type: 'success'
                    })
                    
                    that.setSwitch(response.data.data)
                    that.running = false
                    that.editMprn = false
                })
            }).catch(() => {
                that.$message({
                    message: 'Problem with report sending!',
                    type: 'error'
                })
            }).finally( () => {
                that.running = false;
            })
        }
    },
    computed: {
        property: function () {
            if (!this.swap) {
                return null
            }
            return this.swap.property
        },
        user: function () {
            if (!this.swap) {
                return null
            }
            return this.swap.user
        },
        product: function () {
            if (!this.swap) {
                return null
            }
            return this.swap.recommended_product
        },
        tariff: function () {
            if (!this.swap) {
                return null
            }
            return this.swap.recommended_tariff
        },
        
    },
    
    filters: {
        titleize(value, options, description = false) {
            let filtered = options.filter((option => option.id === value));
    
            try {
                if (filtered) {
                    if (description) {
                        return filtered.pop().description
                    }
                    return filtered.pop().title
    
    
                }
            } catch (e) {
                console.log(value, options);
            }
            return "--"
        }
    },
    mounted() {
        let that = this;
        
        if (that.property != null) return
        
        
        api.switchDetails(this.$route.params.id).then(response => {
            this.setSwitch(response.data.data)
        })
        
        
        api.survey().then(surveyResponse => {
            api.switchDetails(this.$route.params.id).then(switchResponse => {
                that.setSwitch(switchResponse.data.data)
                that.setSurveyData(surveyResponse.data.data)
            })
            
        })
    },
    beforeRouteEnter: (to, from, next) => {
        api.survey().then(surveyResponse => {
            api.switchDetails(to.params.id).then(switchResponse => {
                next(vm => {
                    vm.setSwitch(switchResponse.data.data)
                    vm.setSurveyData(surveyResponse.data.data)
                })
            }).catch(() => {
                next(vm => {
                    vm.$Progress.fail()
                })
            })
        })
    }
}
</script>

<style  lang="scss">
    .el-card__body > .el-row > .el-col {
        margin-bottom: 40px;
      
    }
</style>
