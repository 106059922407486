import {capitalize} from 'lodash'
export default {
    name: 'StatusColoring',
    methods: {
        statusColor: function (status){
            switch (status) {
                case 'pending':
                    return 'text-gray'
                case 'canceled':
                    return 'text-red'
                case 'completed':
                    return 'text-success'
                case 'initiated':
                case 'objection':
                case 'scheduled':
                case 'waiting':
                    return 'text-orange'
            }
        }
    },
    filters: {
        humanStatus: (status) => {
            switch (status) {
                case 'pending':
                case 'scheduled':
                case 'canceled':
                case 'completed':
                case 'waiting':
                    return capitalize(status)
                case 'initiated':
                    return 'In Progress'
                case 'objection':
                    return 'Objected'
            }
        }
    }
}
