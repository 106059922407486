<template>
    <el-row>
        <el-col>
            <el-card>
                <div slot="header" class="clearfix">
                    <h2 class="page-header">
                        Referrals
                        <el-button v-if="!forPayout && !forUser" icon="el-icon-search" class="pull-right" size="small"
                                   @click="filtering = !filtering">
                            Filter
                        </el-button>
                    </h2>
                    <transition name="el-zoom-in-top">
                        <div v-if="filtering">
                            <el-card>
                                <el-form :inline="true">
                                    <el-form-item label="Referrer">
                                        <el-select v-model="filters[0].value" clearable filterable remote
                                                   :remote-method="searchUsers"
                                                   :loading="loading" placeholder="type in to search">
                                            <el-option v-for="(user, key) in users" :key="key" :value="user.id"
                                                       :label="`${user.title} ${user.first_name} ${user.last_name} (${user.email})`"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Referee">
                                        <el-select v-model="filters[1].value" clearable filterable remote
                                                   :remote-method="searchUsers"
                                                   :loading="loading" placeholder="type in to search">
                                            <el-option v-for="(user, key) in users" :key="key" :value="user.id"
                                                       :label="`${user.title} ${user.first_name} ${user.last_name} (${user.email})`"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Achieved">
                                        <el-select v-model="filters[2].value" placeholder="Achieved" clearable>
                                            <el-option label="Yes" :value="true"/>
                                            <el-option label="No" :value="false"/>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="Paid out">
                                        <el-select v-model="filters[3].value" placeholder="Paid out" clearable>
                                            <el-option label="Yes" :value="true"/>
                                            <el-option label="No" :value="false"/>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </el-card>
                        </div>
                    </transition>
                </div>
                <data-tables-server :data="rewards" @query-change="loadData"
                                    class="clickable-rows"
                                    :pagination-props="{background: true, pageSizes: [15, 30, 60,100]}"
                                    :current-page="currentPage" :page-size="pageSize" :total="total" :filters="filters">
                    <el-table-column prop="id" label="ID" width="60" />
    
                    <el-table-column prop="referrer" label="Referrer" v-if="!forPayout && !forUser">
                        <template slot-scope="scope">
                            <router-link :to="{name: 'UsersShow', params: {id: scope.row.referrer.id }}"
                                         v-if=" scope.row.referrer.id">
                                {{ scope.row.referrer.first_name }} {{ scope.row.referrer.last_name }}
                            </router-link>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="referee" label="Referee">
                        <template slot-scope="scope">
                            <router-link :to="{name: 'UsersShow', params: {id: scope.row.referee.id }}"
                                         v-if="scope.row.referee">
                                {{ scope.row.referee.first_name }} {{ scope.row.referee.last_name }}
                            </router-link>
                            <span v-else>--</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="amount" label="Amount">
                        <template slot-scope="scope">
                            <span>&pound;{{scope.row.amount}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="Referred date">
                        <template slot-scope="scope">
                            <span>{{scope.row.created_at | dateFormat}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="achieved_at" label="Achieved date">
                        <template slot-scope="scope">
                            <span v-if="scope.row.achieved_at !== 0">{{scope.row.achieved_at | dateFormat}}</span>
                            <span v-else class="text-warning">Not achieved</span>
                        </template>
                    </el-table-column>
                    <el-table-column :prop="null" label="Payout" v-if="!forPayout && !forUser">
                        <template slot-scope="scope">
                            <div v-if="scope.row.payout">
                            <el-button v-for="(button, key) in actionButtons(scope.row)"
                                       @click="button.handler" :key="key" v-bind="button.props">
                                {{ button.name }}
                            </el-button>
                            </div>
                        </template>
                    </el-table-column>
                </data-tables-server>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
import api from "@/lib/api";
import paginationAndFiltering from "@/mixins/paginationAndFiltering";
const moment = require('moment');
export default {
name: "Referrals",
    mixins: [paginationAndFiltering],
    props: {
        forUser: {
            type: Number,
            default: 0
        },
        forPayout: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            rewards: [],
            loading: false,
            users: [],
            filters: [
                {
                    value: this.$route.query.referrer || '',
                    prop: 'referrer'
                },
                {
                    value: this.$route.query.referee || '',
                    prop: 'referee'
                },
                {
                    value: this.$route.query.achieved? (this.$route.query.achieved === 'true' ? true : false) : '',
                    prop: 'achieved'
                },
                {
                    value: this.$route.query.paid_out? (this.$route.query.paid_out === 'true' ? true : false) : '',
                    prop: 'paid_out'
                },
            ],
        }
    },
    filters: {
        dateFormat: function (date) {
            return moment(date * 1000).format('YYYY-MM-DD')
        }
    },
    methods: {
        loadData: function (queryInfo) {
            this.loadDataParent(queryInfo)
            let that = this;
            that.$Progress.start()
            
            if(this.forUser){
                that.query['referrer'] = this.forUser
            }
            
            if(this.forPayout){
                that.query['payout'] = this.forPayout
            }
            
            api.rewards(that.query).then(response => {
                that.rewards = response.data.data
                if (response.data.pagination) {
                    that.setPagination(response.data.pagination)
                }
                that.$Progress.finish()
            }).catch(() => {
                that.$Progress.fail()
            })
        },
        searchUsers: function (term) {
            if (!term) {
                return;
            }
        
            let that = this;
            that.loading = true;
            api.users({user: term}).then(response => {
                that.users = response.data.data;
            }).catch(() => {
                that.users = [];
            }).finally(() => {
                that.loading = false;
            })
        },
        actionButtons: function (row) {
            return [
                {
                    props: {
                        type: 'primary',
                    },
                    handler: () => {
                        this.$router.push({name: 'PayoutsShow', params: {id: row.payout.id}})
                    },
                    name: 'Payout details'
                }
            ]
        },
    },
}
</script>

<style scoped>

</style>
