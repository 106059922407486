<template>
    <el-menu :default-active="activeItem" :collapse="isCollapse">
        <el-menu-item v-for="(item, key) in menuItems" :key="key" :index="String(key)" @click="$router.push(item.route)">
            <i  v-if="item.icon" :class="item.icon"></i>
            <span slot="title">{{item.title}}</span>
        </el-menu-item>
    </el-menu>
</template>

<script>
import {findIndex} from 'lodash'
const menuItems = [
    {
        icon: 'el-icon-s-cooperation',
        title: 'Suppliers',
        route: {
            name: 'Suppliers'
        }
    },
    {
        icon: 'el-icon-user-solid',
        title: 'Users',
        route: {
            name: 'Users'
        }
    },
    {
        icon: 'el-icon-s-home',
        title: 'Switches',
        route: {
            name: 'Switches'
        }
    },
    {
        icon: 'el-icon-help',
        title: 'Support Tickets',
        route: {
            name: 'Tickets'
        }
    },
    {
        icon: 'el-icon-message-solid',
        title: 'Better deals',
        route: {
            name: 'Better'
        }
    },
    {
        icon: 'el-icon-connection',
        title: 'Referrals',
        route: {
            name: 'Referrals'
        }
    },
    {
        icon: 'el-icon-money',
        title: 'Payouts',
        route: {
            name: 'Payouts'
        }
    },
    {
        icon: 'el-icon-attract',
        title: 'Pre-registrations',
        route: {
            name: 'Registrations'
        }
    },
]
export default {
name: "AppMenu",
    props: {
        isCollapse: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            menuItems: menuItems
        }
    },
    computed: {
        activeItem() {
            let that = this;
            let index = findIndex(this.menuItems, item => that.$route.matched.some(record => record.name === item.route.name) ) || 0;
            return String(index)
        }
    },
}
</script>

<style scoped lang="scss">

</style>
