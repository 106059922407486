<template>
    <div class="content bg-gray-light h-100">
        <!-- Content Header (Page header) -->
        <section class="content-header">
            <el-card>
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ name: 'DashboardHome' }"><i class="el-icon-s-home"></i> Home</el-breadcrumb-item>
                    <el-breadcrumb-item v-for="(route, key) in routes" :key="key" :to="{name: route.name}">
                        {{ route.meta.title}}
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </el-card>
        </section>
        
        <section class="content">
            <transition name="page" mode="out-in">
                <router-view></router-view>
            </transition>
        </section>
        <!-- /.content -->
    </div>
</template>

<script>
export default {
name: "AppContent",
    computed: {
        routes: function () {
            return this.$route.matched.filter(r => !!r.meta.title)
        }
    },
}
</script>

<style scoped>

</style>
