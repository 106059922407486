import {each, isEqual} from "lodash";

export default {
    data() {
        return {
            currentPage: parseInt(this.$route.query.page) || 1,
            pageSize: parseInt(this.$route.query.pageSize) || 1,
            total: 0,
            filtering: false,
            query: {},
            sort: {
                prop: 'id',
                order: null
            },
        }
    },
    methods: {
        loadDataParent: function (queryInfo){

            let that = this;
            that.query = {};

            that.pageSize = queryInfo.pageSize;

            each(queryInfo.filters, function (f) {
                if (f.value !== "") {
                    if(f.value  === true){
                        that.query[f.prop] = 1
                    } else if(f.value  === false) {
                        that.query[f.prop] = 0
                    } else {
                        that.query[f.prop] = f.value
                    }

                }
            })

            if(queryInfo.type === 'sort'){
                that.sort = queryInfo.sort;
            }

            if(that.sort.order){
                that.query.order = that.sort.prop;
                that.query.direction = that.sort.order === 'ascending' ? 'asc' : 'desc';
            }

            if(Object.values(that.query).length){
                this.filtering = true
            }

            if (queryInfo.page > 1) {
                that.query.page = queryInfo.page
            }

            if (queryInfo.pageSize > 15) {
                that.query.pageSize = queryInfo.pageSize
            }

            if(!isEqual(that.$route.query, that.query)){
                that.$router.push({name: that.$route.name, query: that.query})
            }

        },
        setPagination: function (pagination) {
            this.total = pagination.total
        }
    }
}
