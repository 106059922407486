import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth" */ '@/views/auth/Login')
    },

    {
        path: '/suppliers',
        name: 'Suppliers',
        component: () => import(/* webpackChunkName: "suppliers" */ '@/views/EmptyRouterView'),
        meta: {
            auth: true,
            title: 'Suppliers'
        },
        children: [
            {
                path: '',
                name: 'Suppliers',
                component: () => import(/* webpackChunkName: "suppliers" */ '@/views/dashboard/Suppliers'),
            },
            {
                path: ':id',
                name: 'SuppliersShow',
                component: () => import(/* webpackChunkName: "suppliers" */ '@/views/dashboard/SuppliersShow'),
                meta: {
                    title: 'Details'
                }
            },
        ]
    },
    {
        path: '/users',
        name: 'Users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/EmptyRouterView'),
        meta: {
            auth: true,
            title: 'Users'
        },
        children: [
            {
                path: '',
                name: 'Users',
                component: () => import(/* webpackChunkName: "users" */ '@/views/dashboard/Users'),
            },
            {
                path: ':id',
                name: 'UsersShow',
                component: () => import(/* webpackChunkName: "users" */ '@/views/dashboard/UsersShow'),
                meta: {
                    title: 'Details'
                }
            },
        ]
    },
    {
        path: '/switches',
        name: 'Switches',
        component: () => import(/* webpackChunkName: "properties" */ '@/views/EmptyRouterView'),
        meta: {
            auth: true,
            title: 'Switches'
        },
        children: [
            {
                path: '',
                name: 'Switches',
                component: () => import(/* webpackChunkName: "properties" */ '@/views/dashboard/Switches'),
            },
            {
                path: ':id',
                name: 'SwitchesShow',
                component: () => import(/* webpackChunkName: "properties" */ '@/views/dashboard/SwitchesShow'),
                meta: {
                    title: 'Details'
                }
            },
        ]
    },
    {
        path: '/tickets',
        name: 'Tickets',
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/EmptyRouterView'),
        meta: {
            auth: true,
            title: 'Tickets'
        },
        children: [
            {
                path: '',
                name: 'Tickets',
                component: () => import(/* webpackChunkName: "tickets" */ '@/views/dashboard/Tickets'),
            },
        ]
    },
    {
        path: '/better',
        name: 'Better',
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/EmptyRouterView'),
        meta: {
            auth: true,
            title: 'Better'
        },
        children: [
            {
                path: '',
                name: 'Better',
                component: () => import(/* webpackChunkName: "tickets" */ '@/views/dashboard/BetterDeals'),
            },
        ]
    },
    {
        path: '/referrals',
        name: 'Referrals',
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/EmptyRouterView'),
        meta: {
            auth: true,
            title: 'Referrals'
        },
        children: [
            {
                path: '',
                name: 'Referrals',
                component: () => import(/* webpackChunkName: "tickets" */ '@/views/dashboard/Referrals'),
            },
        ]
    },
    {
        path: '/payouts',
        name: 'Payouts',
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/EmptyRouterView'),
        meta: {
            auth: true,
            title: 'Payouts'
        },
        children: [
            {
                path: '',
                name: 'Payouts',
                component: () => import(/* webpackChunkName: "tickets" */ '@/views/dashboard/Payouts'),
            },
            {
                path: ':id',
                name: 'PayoutsShow',
                component: () => import(/* webpackChunkName: "properties" */ '@/views/dashboard/PayoutsShow'),
                meta: {
                    title: 'Details'
                }
            },
        ]
    },
    {
        path: '/registrations',
        name: 'Registrations',
        component: () => import(/* webpackChunkName: "tickets" */ '@/views/EmptyRouterView'),
        meta: {
            auth: true,
            title: 'Pre-registrations'
        },
        children: [
            {
                path: '',
                name: 'Registrations',
                component: () => import(/* webpackChunkName: "tickets" */ '@/views/dashboard/Registrations'),
            }
        ]
    },
    {
        path: '/',
        name: 'DashboardHome',
        // redirect: { name: 'DashboardHome'},
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard/Home'),
        meta: {
            auth: true
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.auth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!store.getters["auth/isAuthenticated"]) {
            next({name: 'Login'})
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
