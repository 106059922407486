<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="12" class="text-right font-weight-bold">Supplier</el-col>
            <el-col :span="12" class="text-left text-gray">{{ deal.recommended_product.supplier.name }}</el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12" class="text-right font-weight-bold">Tariff</el-col>
            <el-col :span="12" class="text-left text-gray">{{ deal.recommended_product.name }}</el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12" class="text-right font-weight-bold">Term</el-col>
            <el-col :span="12" class="text-left text-gray">{{ deal.recommended_tariff.term || 'Variable' }}</el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12" class="text-right font-weight-bold">Savings</el-col>
            <el-col :span="12" class="text-left text-gray">&pound;{{ deal.annual_savings }}</el-col>
        </el-row>
        <el-row :gutter="20" v-if="deal.annual_estimation">
            <el-col :span="12" class="text-right font-weight-bold">Estimated Monthly Payment</el-col>
            <el-col :span="12" class="text-left text-gray">
                &pound;{{ deal.annual_estimation / 12 | round }}
                <!--                        <span v-if="deal.estimate.monthlyPaymentDiff">( {{ deal.estimate.monthlyPaymentDiff }} Less than you pay now! )</span>-->
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12" class="text-right font-weight-bold">Payment method</el-col>
            <!--                    <el-col :span="12" class="colext-left text-gray">{{ deal.paymentMethod }}</div>-->
            <el-col :span="12" class="text-left text-gray">Direct debit</el-col>
        </el-row>
        <el-row :gutter="20"
             v-if="energySourceIs(['D','E']) && (deal.recommended_tariff.prices.price_electricity_daily > 0|| deal.recommended_tariff.prices.price_electricity_standard > 0 )">
            <el-col :span="12" class="text-right font-weight-bold">Unit rate 9 (Electricity)</el-col>
            <el-col :span="12" class="text-left text-gray">
                <div v-if="deal.recommended_tariff.e7">
                    <p class="m-0">Day rate {{ deal.recommended_tariff.prices.price_electricity_daily }}p per kWh</p>
                    <p class="m-0">Night rate {{ deal.recommended_tariff.prices.price_electricity_nightly }}p per kWh</p>
                </div>
                <div v-else>
                    <p class="m-0">Standard rate &pound;{{ deal.recommended_tariff.prices.price_electricity_standard }}p per kWh</p>
                </div>
            </el-col>
        </el-row>
        <el-row :gutter="20" v-if="energySourceIs(['D','E']) && deal.recommended_tariff.prices.standing_charge_electricity > 0">
            <el-col :span="12" class="text-right font-weight-bold">Standing charge (Electricity)</el-col>
            <el-col :span="12" class="text-left text-gray">{{ deal.recommended_tariff.prices.standing_charge_electricity }}p per day</el-col>
        </el-row>
        <el-row :gutter="20" v-if="energySourceIs(['D','G']) && deal.recommended_tariff.prices.price_gas_standard > 0">
            <el-col :span="12" class="text-right font-weight-bold">Unit rate (Gas)</el-col>
            <el-col :span="12" class="text-left text-gray">{{ deal.recommended_tariff.prices.price_gas_standard }}p per kWh</el-col>
        </el-row>
        <el-row :gutter="20" v-if="energySourceIs(['D','G']) && deal.recommended_tariff.prices.standing_charge_gas > 0">
            <el-col :span="12" class="text-right font-weight-bold">Standing charge (Gas) </el-col>
            <el-col :span="12" class="text-left text-gray">{{ deal.recommended_tariff.prices.standing_charge_gas }}p per day</el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :span="12" class="text-right font-weight-bold">Exit fees</el-col>
            <el-col :span="12" class="text-left text-gray">Electricity: &pound;{{ deal.recommended_tariff.prices.exit_fee_elec || 0}}; Gas: &pound;{{ deal.recommended_tariff.prices.exit_fee_gas || 0}}</el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    name: 'DealTariffSummary',
    props: {
        deal: {},
        type: {
            type: String,
            default: '',
        }
    },
    filters: {
        round: function (value, decimals) {
            if (!value) {
                value = 0
            }
            
            if (!decimals) {
                decimals = 2
            }
            
            value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals)
            return value
        }
    },
    methods: {
        energySourceIs: function (sourceType) {
            if (!Array.isArray(sourceType)) {
                sourceType = [sourceType]
            }
            if (!this.type) {
                return true
            }
            
            return sourceType.indexOf(this.type) >= 0
        },
    },
}
</script>
<style scoped lang="scss">
@import "../sass/base";

.text-gray {
    color: $gray-600;
}

</style>
